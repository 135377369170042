import ApiRequest    from '../../api/request.js';

async function schemaFieldToFormInput(schemaField, token)
{
  //console.log(schemaField);
  // If type is in this array then make it a select validation
  const selectTypes = ['reference', 'select'];
  if(schemaField.name !== 'isDeleted' && schemaField.name !== 'createdBy')
  {
    if(schemaField.type === undefined)
    {
      console.log(schemaField);
    }

    if(schemaField.type === 'point')
    {
      console.log(schemaField);
    }

    var type = schemaField.type.replace('string', 'text').replace('secure', 'password').replace('reference', 'select');

    // Set type to location if reference and reference is location model
    if(schemaField.type === 'reference' && schemaField.reference == 'location')
    {
      type = 'location';
    }

    var options = null;
    var element = 'input';
    if(type === 'string' && schemaField.maxLength < 48 || type === 'secure' || type === 'file')
    {
      element = 'input';
    }
    else if((type === 'string' && schemaField.maxLength >= 48) || type === 'point')
    {
      element = 'textarea';
    }
    else if(type === 'select')
    {
      element = 'select';
      // Garbage: ~~If its an array the field name is most likely plural so lets parse out the last s~~
      var modelname = schemaField.reference;
      /*if(schemaField.isArray)
      {
        if(schemaField.name.charAt(schemaField.name.length - 1) === 's')
        {
          modelname = schemaField.name.substr(0, schemaField.name.lastIndexOf('s'));
        }
      }*/
      // If values provided in schema field build list out of that
      if(schemaField.values && schemaField.values.length > 0)
      {
        options = schemaField.values.split(',').map( (value) =>
        {
          return {text: value, value: value};
        });
      } // Need to query model documents and build option list
      // Backend will handle validating permissions if we can query or not
      else
      {
        var params = { model: modelname, params: {} };
        var response = await ApiRequest.sendRequest("post", params, "data/query", token);
        console.log(response.data);

        if(response.data.error !== null)
        {
          return {error: response.data.error, model: modelname };
        }

        // Build option list
        options = response.data.results.map( (ptr) =>
        {
          return {text: ptr[schemaField.keyInReference], value: ptr._id};
        });
      }
    }
    else if(type === 'boolean')
    {
      element = 'select';
      options = [{text: 'True', value: 'true'}, {text: 'False', value: 'false'}];
    }
    else if(type === 'space')
    {
      element = 'space';
    }
    else if(type === 'datetime')
    {
      element = 'datetime';
    }
    else if(type === 'date')
    {
      element = 'date';
    }

    var validation = {};
    if(schemaField.required)
    {
      let requirement = 'A ' + schemaField.name + ' is required';
      if(schemaField.name.indexOf('_confirm_') !== -1)
      {
        requirement = 'Confirming ' + schemaField.name.replace('_confirm_', '') + ' is required'
      }
      validation =
      {
        requirement: requirement,
        algorithm: selectTypes.indexOf(schemaField.type) !== -1 ? 'select' : 'range',
        min: schemaField.type === 'boolean' ? 0 : schemaField.minLength,
        max: schemaField.type === 'boolean' ? 9 : schemaField.maxLength
      };
    }

    const formInput =
    {
      //label: schemaField.name,
      id: schemaField.name,
      element: element,
      type: type,
      required: schemaField.required,
      value: '',
      multiSelectEnabled: schemaField.isArray,
      placeholder: schemaField.placeholder,
      tooltip: schemaField.tooltip,
      tooltipQuestion: schemaField.tooltipQuestion,
      tooltipType: schemaField.tooltipType
    };
    if(formInput.type === 'select' || formInput.type === 'boolean')
    {
      formInput.options = options;
      formInput.reference = schemaField.reference;
      formInput.key = schemaField.keyInReference;
      //console.log(formInput);
    }
    if(formInput.type !== 'file' && formInput.type !== 'space')
    {
      formInput.validation = validation;
      if(!schemaField.placeholder)
      {
        if(schemaField.name)
        {
          let words = schemaField.name.split(/(?=[A-Z])/);
          formInput.placeholder = 'Enter';
          for(let i = 0; i < words.length; i++)
          {
            formInput.placeholder += (' ' + words[i].toLowerCase());
          }
        }
      }
    }
    if(formInput.type === 'link')
    {
      formInput.to = schemaField.to;
      formInput.text = schemaField.text;
    }
    else if(formInput.type === 'button')
    {
      formInput.route = schemaField.route;
      formInput.successActions = schemaField.successActions;
      formInput.title = schemaField.title;
      formInput.disabled = schemaField.disabled;
      //formInput.disabled = true;
    }
    if((schemaField.row || schemaField.row === 0) && (schemaField.col || schemaField.col === 0))
    {
      formInput.row = schemaField.row;
      formInput.col = schemaField.col;
    }
    if(formInput.type === '_form_footer_')
    {
      formInput.title = schemaField.title;
    }

    // Only put label on form if it's actually there
    if(schemaField.label && schemaField.label.length > 0)
    {
      if(schemaField.name.indexOf('_confirm_') !== -1)
      {
        formInput.label = 'Confirm ' + schemaField.label;
      }
      else
      {
        formInput.label = schemaField.label;
      }
    }

    return formInput;
  }
  return null;
}

function getSelectedFiles(input)
{
  if(input.hasAttribute('multiple'))
  {
    return [].slice.call(input.files)
      .map((file) => file.name)
      .join(', ')
  }

  if (input.value.indexOf('fakepath') !== -1)
  {
    const splittedValue = input.value.split('\\')
    return splittedValue[splittedValue.length - 1]
  }

  return input.value
}

function extractValueFromPointer(iFieldName, iRow)
{
  //console.log(iFieldName);

  var visibleText = "";
  var fieldName = iFieldName;
  var fieldNameInPtr = "";
  var row = iRow;

  // Get total pointers in key
  let occurrences = (fieldName.match(/\./g) || []).length;
  if(occurrences === 0)
  {
    return iRow[iFieldName];
  }

  // Iterate all pointers
  var splitIndex = -1;
  for(var i = 0; i < occurrences; i++)
  {
    splitIndex = fieldName.indexOf('.');
    try
    {
      // Extract pointer
      fieldNameInPtr  = fieldName.substring(splitIndex + 1);
      fieldName = fieldName.substring(0, splitIndex);

      // Slowly parse down the data
      if(fieldNameInPtr.indexOf('.') !== -1)
      {
        row = row[fieldName];
      }
      else
      {
        return row[fieldName][fieldNameInPtr];
      }
      fieldName = fieldNameInPtr;
    }
    catch(err)
    {
      console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
      return "";
    }
  }
}
export { extractValueFromPointer, schemaFieldToFormInput, getSelectedFiles };
