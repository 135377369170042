import React from 'react';
import { withRouter } from 'react-router'
import { ApiManager, DataManager } from '../../../managers';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

const COMPONENT_NAME = 'navList';

/**
  * Navigation list
  *
  * @param 	{String}	default 	The default page to treat as home link
  *
  *	State:
  */
class NavList extends React.Component
{
  _dataMgr = null;

  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tNavList()");
    super(props);
    this.state =
    {
      component:
      {
        navData: [],
        dynamicData: props.layout === 1,
      },
      dataVersion: 0, // Allow data manager to update us using this
    };

    this._dataMgr = DataManager.GetInstance();
  }

  async componentDidMount()
  {
    console.log('\tNavList.componentDidMount()');
    this._isMounted = true;

    if(this.props.layout === 1)
    {
      this.loadData();
    }

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    'app');
  }

  componentWillMount()
  {
    this._dataMgr.removeObserver(COMPONENT_NAME);
  }

  // MARK: - API
  loadData = async() =>
  {
    // Fetch data
    this.props.setIsLoading(true);
    const response = await ApiManager.SendRequest(
    {
      route: 'component',
      action: 'side-nav'
    });
    if(response.error !== null)
    {
      this.props.setIsLoading(false);
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    console.log(response.results);

    // Update parent
    const component = {...this.state.component};
    if(this.state.component.dynamicData)
    {
      component.navData = response.results;
    }

    this.setState({ component: component });
    this.props.setIsLoading(false);
    return true;
  }


  // MARK: - Onchange handlers
  navOnChange = (link) =>
  {
    if(this.props.active != link)
    {
      // If we are on the admin page then just pass this prop down to the data manager
      // no need to update URL
      if(this.props.user &&
        (this.props.user.authorization.type !== 'admin' && this.props.user.authorization.type !== 'customer'))
      {
        this.props.history.push('/' + link.toLowerCase());
      }

      if(this.props.location.pathname !== 'home')
      {
        this.props.setSelectedNavItem(link, () => this.props.history.push('/home'));
      }
      else
      {
        this.props.setSelectedNavItem(link, () => console.log(''));
      }
    }
  }


	// MARK: - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    // If manually clearing active nav item call prop onChange
    return (
      this.props.active !== nextProps.active ||
      this.props.user._id.toString() !== nextProps.user._id.toString() ||
      this.props.user.photo !== nextProps.user.photo ||
      this.props.siteManager !== nextProps.siteManager ||
      this.state.component.navData !== nextState.component.navData ||
      this.props.isShowing != nextProps.isShowing ||
      this.props.dataVersion !== nextProps.dataVersion
    );
  }

  render()
  {
    console.log('\tNavList.render()');
    let name = '';
    if(this.props.user)
    {
      name = (this.props.user.name ? this.props.user.name : (this.props.user.firstName + ' ' + this.props.user.lastName));
    }

    const app = this._dataMgr.getData('app');

    if(this.props.layout === 1)
    {
      return (
        <Layout1
          user={this.props.user}
          navData={this.state.component.navData}
          name={name}
          navOnChange={this.navOnChange}
          active={this.props.active}
        />
      );
    }
    else
    {
      return (
        <Layout2
          user={this.props.user}
          name={name}
          navOnChange={this.navOnChange}
          isShowing={this.props.isShowing}
          active={this.props.active}
          setIsShowing={this.props.setIsShowing}
          activeApp={app.active}
        />
      );
    }
  }
}

export default withRouter(NavList);
