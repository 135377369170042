import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getSelectedFiles } from '../../components/Form/utility';

import { Nav, Form } from 'react-bootstrap';

import Colors from '../../constant/colors';
import AppText from '../../constant/text';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    color: Colors.posts.create.title.color
  },
  okayBtn: {
    color: Colors.form.popupDialog.okayBtn.color,
    backgroundColor: Colors.form.popupDialog.okayBtn.color
  }
});


const DialogActions = withStyles((theme) => ({
  root: {
    margin: 'auto',
    padding: theme.spacing(1),
    backgroundColor: Colors.posts.create.backgroundAccent.color,
    width: '100%',
  },
}))(MuiDialogActions);


export const Create = ({onClose,
                        imgPath,
                        title,
                        textTitle,
                        filesTitle,
                        siteManager,
                        updateActiveTab,
                        updateNewPost,
                        create,
                        component }) =>
{
  var bgColor = '#225473';
  let postBgColor = '#71A241'
  let cancelBgColor = bgColor;
  var textColor = '#FFF';
  if(siteManager !== null && siteManager !== undefined)
  {
    //console.log(props.siteManager);
    bgColor = siteManager.getColorFor('Header', 'Background');
    textColor = siteManager.getColorFor('Header', 'Text');
  }

  const dialogStyle =
  {
    width: '80%'
  }
  const titleStyle =
  {
    color: bgColor,
    display: 'inline-block',
    marginLeft: '10px',
  }
  const dialogHeaderStyle =
  {
    borderRadius: 0,
    padding: '5px 40px'
  }
  const dialogContentStyle =
  {
    color: textColor,
    padding: '16px',
    backgroundColor: Colors.posts.create.backgroundAccent.color,
  }
  const postButtonStyle =
  {
    color: textColor,
    borderRadius: '20px',
    border: 0,
    padding: '6px 10px 8px 10px',
    background: postBgColor,
  }
  const cancelButtonStyle =
  {
    color: textColor,
    borderRadius: '20px',
    border: 0,
    padding: '6px 10px 8px 10px',
    background: cancelBgColor,
  }
  const postButtonImgStyle =
  {
    color: bgColor
  }
  const postButtonContainer =
  {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: 0,
    position: 'relative',
  }
  const unactiveLinkTextStyle =
  {
    color: textColor
  }

  const fileKeys = Object.keys(component.detail.files);
  let fileLabel = AppText.posts.create.fileLabel;
  if(fileKeys.length > 0)
  {
    fileLabel = '';
    for(let i = 0; i < fileKeys.length; i++)
    {
      fileLabel += component.detail.files[fileKeys[i]].name;
      if(i !== fileKeys.length -1)
      {
        fileLabel += ', ';
      }
    }
  }

  return (
  <Dialog
    onClose={onClose}
    aria-labelledby="title"
    open={component.isOpen}
    fullWidth={true}
  >
    <div style={dialogHeaderStyle}>
      <h2>{imgPath.length > 0 &&
        <img
          src={require('../../asset/' + imgPath)}
          className='popup-dialog-icon'
        />}
        <p style={titleStyle}>{title}</p>
      </h2>
    </div>

    <div style={dialogContentStyle}>
      <Nav fill variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Nav.Link
            onClick={() => updateActiveTab('text-tab')}
            eventKey='text-tab'
            active={component.activeTab === 'text-tab' ? true : false}
            style={component.activeTab === 'text-tab' ? {} : unactiveLinkTextStyle}
          >
            <span style={postButtonImgStyle} className="ti-pencil-alt2" />
            {` ${textTitle}`}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => updateActiveTab('files-tab')}
            eventKey="files-tab"
            active={component.activeTab === 'files-tab' ? true : false}
            style={component.activeTab === 'files-tab' ? {} : unactiveLinkTextStyle}
          >
            <span style={postButtonImgStyle} className="ti-camera" />
            {` ${filesTitle}`}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {component.activeTab === 'text-tab' &&
      <Form.Group controlId="text-input">
        <Form.Control
          as="textarea"
          rows="8"
          onChange={(e) => updateNewPost('text', e.target.value)}
          value={component.detail.text}
        />
      </Form.Group>}
      {component.activeTab === 'files-tab' &&
      <Form.Group controlId="files-input">
        <div className="custom-file">
          <input
            className="custom-file-input"
            id="files-button"
            type='file'
            value={''}
            multiple
            onChange={(e) =>
            {
              // Adjust UI
              const label = e.target.parentNode.querySelector('.custom-file-label');
              if(label)
              {
                const inputVal = getSelectedFiles(e.target);
                console.log(inputVal);
                if(inputVal.length)
                {
                  label.textContent = inputVal;
                }
                else
                {
                  label.textContent = AppText.posts.create.fileLabel;
                }
              }
              // Update parent state
              updateNewPost('files', {...e.target.files});
            }}
          />
          <label className="custom-file-label" htmlFor="files-button">{fileLabel}</label>
        </div>
      </Form.Group>}
    </div>
    <DialogActions>
      <button
        onClick={onClose}
        style={cancelButtonStyle}
      >{AppText.posts.create.cancel}</button>
      <button
        onClick={create}
        style={postButtonStyle}
      >{AppText.posts.create.submit}</button>
    </DialogActions>
  </Dialog>
  );
};

export default Create;
