import React from 'react';
import StyledComponent from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Button, Form } from 'react-bootstrap';
import { Comment }  from './';

import AppText from '../../constant/text';

export const Comments = ({  onChange,
                            onClick,
                            updateComment,
                            deleteComment,
                            comments,
                            user,
                            newCommentText }) =>
{
  // TODO: Turn this into comment component that uses schema field and page.component
  return (
  <Paper>
    <div className='comments-row'>
      <div className='col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12'>
        <Form.Control
          type="text"
          placeholder={AppText.posts.post.comments.sendPlaceholder}
          onChange={onChange}
          value={newCommentText}
        />
      </div>
      <div className='col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12'>
        <Button
          onClick={onClick}
        >{AppText.posts.post.comments.sendButton}</Button>
      </div>
      {comments.map( (comment, i) =>
      {
        return (<Comment
                  key={'comment-' + i}
                  comment={comment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  canEdit={user._id.toString() === comment.createdBy._id.toString()}
                  canReport={user._id.toString() !== comment.createdBy._id.toString()}
                />);
      })}
    </div>
  </Paper>);
}

export default Comments;
