import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

function Header({ onSelectAllClick,
                  order,
                  orderBy,
                  numSelected,
                  rowCount,
                  onRequestSort,
                  selectEnabled,
                  selectAllEnabled,
                  headers,
                  sortEnabled,
                  layout })
{
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  if(layout === 1)
  {
    return (
      <Layout1
        onSelectAllClick={onSelectAllClick}
        order={order}
        orderBy={orderBy}
        numSelected={numSelected}
        rowCount={rowCount}
        selectEnabled={selectEnabled}
        selectAllEnabled={selectAllEnabled}
        headers={headers}
        sortEnabled={sortEnabled}
        createSortHandler={createSortHandler}
      />
    );
  }
  else
  {
    return (
      <Layout2
        onSelectAllClick={onSelectAllClick}
        order={order}
        orderBy={orderBy}
        numSelected={numSelected}
        rowCount={rowCount}
        selectEnabled={selectEnabled}
        selectAllEnabled={selectAllEnabled}
        headers={headers}
        sortEnabled={sortEnabled}
        createSortHandler={createSortHandler}
      />
    );
  }
}

Header.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default Header;
