import ApiRequest  from '../api/request.js';


export default class Classy
{
  /**
    Interact with chat routes
    @note Parameters come in an object named params and may contain more than what is just labeled here
    look at actual method called for action to determine actual parameters
    @param {String}  params.model is the model type being interacted
    @param  {String}  params.action   Is the action to take (message/conversations)
    @param  {String}  apiToken  Token so we can talk to backend
    @returns  {JSON}  result of new object and error is null or contains error if there
  */
  static async Interact(params, apiToken)
  {
    console.log('\t\tClassy.Interact()');
    try
    {
      if(params.action === 'report')
      {
        return Classy.Report(params, apiToken);
      }
    }
    catch(err)
    {
      return {error: err.message};
    }
  }




  static async Report(params, apiToken)
  {
    console.log('\t\tClassy.Report()');
    try
    {
      let response = await ApiRequest.sendRequest("post", params.params, 'classy/' + params.action, apiToken);
      return response.data;
    }
    catch(err)
    {
      return {error: err.message};
    }
  }
}
