import React from 'react'
import { CFooter } from '@coreui/react'

const Layout2 = ({ copyrightText }) =>
{
  return (
    <CFooter fixed={false}>
      <div>
        <span className="ml-1">{copyrightText}</span>
      </div>
      <div className="mfs-auto">
        <a target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
    </CFooter>
  )
}

export default React.memo(Layout2)
