import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ApiRequest from '../../api/request.js';
import Layout1 from './layout-1';
import Layout2 from './layout-2';

class Notifications extends Component
{
  _manuallyUpdating = false;
  _scrollRef = null;
  _notificationRef = null;
  _containerRef = null;
  _layout = 2;

  constructor(props)
  {
    console.log('Notifications()');
    super(props);

    this.state =
    {
      showing: false,
      styles: {}
    };

    this._scrollRef = React.createRef();
    this._notificationRef = React.createRef();
    this._containerRef = React.createRef();

    // Become observer so we know when notification data changes
    if(props.notificationManager)
    {
      props.notificationManager.addObserver(this);
    }
  }

  componentDidMount()
  {
    document.addEventListener('mousedown', (event) =>
    {
      this.handleClickOutside(event);
    });

    // To make notification container to adjust based on window, if it is placed on right side
    if(this._layout === 1 &&
      this._notificationRef.current.offsetLeft > this._notificationRef.current.offsetWidth)
    {
      this.setState(
      {
        styles:
        {
          ...this.state.styles,
          transform: `translateX(-${this._notificationRef.current.offsetWidth - 20}px)`
        }
      });
    }
  }

  componentWillUnmount()
  {
    document.removeEventListener('mousedown', (event) =>
    {
      this.handleClickOutside(event);
    });
  }

  handleClickOutside = (event) =>
  {
    if(this._containerRef &&
        this._containerRef.current &&
        !this._containerRef.current.contains(event.target))
    {
      this.setState({ showing: false });
    }
  };

  markRead = async (ids) =>
  {
    this.props.updateMasterState({ isLoading: true });
    try
    {
      var response = await ApiRequest.sendRequest("post", {ids: ids}, "notification/mark-read", this.props.cookies.get('token'));
      if(response.data.error !== null)
      {
        this.props.updateMasterState({ isLoading: false });
        return;
      }
      console.log(response.data);
      this.props.notificationManager.setNotifications(response.data.results);
      this.props.updateMasterState({ isLoading: false });
    }
    catch(err)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    return (
      this.state.showing !== nextState.showing ||
        this.state.styles !== nextState.styles
    );
  }

  componentDidUpdate()
  {
    if(this._manuallyUpdating)
    {
      this._manuallyUpdating = false;
    }
  }

  dataReloaded()
  {
    console.log('Notifications.dataReloaded()');
    if(!this._manuallyUpdating)
    {
      this._manuallyUpdating = true;
      this.forceUpdate();
    }
  }

  newNotification(notification)
  {
    console.log('Notifications.newNotification()');
    if(!this._manuallyUpdating)
    {
      this._manuallyUpdating = true;
      this.forceUpdate();
    }
  }

  render()
  {
    console.log('Notifications.render()');
    const notifications = this.props.notificationManager ? this.props.notificationManager.getNotifications() : [];

    // Figure out how many are unread
    let unreadCount = 0;
    for(let i = 0; i < notifications.length; i++)
    {
      if(notifications[i].status === 'unread')
      {
        unreadCount += 1;
      }
    }

    if(this._layout === 1)
    {
      return (
        <Layout1
          containerRef={this._containerRef}
          notificationRef={this._notificationRef}
          scrollRef={this._scrollRef}
          unreadCount={unreadCount}
          toggleShow={() => this.setState({ showing: !this.state.showing })}
          styles={this.state.styles}
          showing={this.state.showing}
          notifications={notifications}
          forward={this.props.forward}
          markRead={this.markRead}
        />
      );
    }
    else
    {
      return (
        <Layout2
          containerRef={this._containerRef}
          notificationRef={this._notificationRef}
          scrollRef={this._scrollRef}
          unreadCount={unreadCount}
          toggleShow={() => this.setState({ showing: !this.state.showing })}
          styles={this.state.styles}
          showing={this.state.showing}
          notifications={notifications}
          forward={this.props.forward}
          markRead={this.markRead}
        />
      );
    }
  }
}

Notifications.defaultProps =
{
  data: [],
  header: {
    title: 'Notifications',
    option: { text: 'Mark all as read', onClick: () => {} }
  },
  style: {}
};

Notifications.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  header: PropTypes.shape({
    title: PropTypes.string,
    option: PropTypes.shape({ text: PropTypes.string, onClick: PropTypes.func })
  }),
  style: PropTypes.shape({})
};

export default Notifications;
