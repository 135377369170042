import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Layout2 = ({  onSelectAllClick,
                    order,
                    orderBy,
                    numSelected,
                    rowCount,
                    onRequestSort,
                    selectEnabled,
                    selectAllEnabled,
                    headers,
                    sortEnabled,
                    createSortHandler }) =>
{
  return (
    <TableHead>
      <TableRow>
        {selectEnabled !== false &&
        <TableCell padding="checkbox">
          {selectAllEnabled &&
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />}
        </TableCell>}
        {headers.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={index === 0 && selectEnabled !== false ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={sortEnabled ? createSortHandler(headCell.id) : null}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default Layout2;
