import React from 'react';
import StyledComponent from 'styled-components';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

const Footer = (props) =>
{
  console.log("\tFooter()");
  return (
    <Layout2
      copyrightText={props.copyrightText}
    />
  );
}
export default Footer;
