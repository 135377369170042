import React from 'react';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

class Toolbar extends React.Component
{
  // MARK: - Constructor
  constructor(props)
  {
    super(props);

    this.state = {};
  }

  // MARK: - Render
  shouldComponentUpdate(nextProps, nextState)
  {
    return (this.props.selected !== nextProps.selected ||
            this.props.isCreateAvailable !== nextProps.isCreateAvailable ||
              this.props.isUpdateAvailable !== nextProps.isUpdateAvailable);
  }

  render()
  {

    if(this.props.layout === 1)
    {
      return (
        <Layout1
          selected={this.props.selected}
          title={this.props.title}
          tableDidFinishLoading={this.props.tableDidFinishLoading}
          tableDidStartLoading={this.props.tableDidStartLoading}
          getTableDataForSelectedRows={this.props.getTableDataForSelectedRows}
          isCsvAvailable={this.props.isCsvAvailable}
          cookies={this.props.cookies}
          model={this.props.model}
          isDeleteAvailable={this.props.isDeleteAvailable}
          isUpdateAvailable={this.props.isUpdateAvailable}
          updateButtonOnClick={this.props.updateButtonOnClick}
          isCreateAvailable={this.props.isCreateAvailable}
          createButtonOnClick={this.props.createButtonOnClick}
          viewButtonOnClick={this.props.viewButtonOnClick}
          isViewAvailable={this.props.isViewAvailable}
          customButton1={this.props.customButton1}
        />
      );
    }
    else
    {
      return (
        <Layout2
          selected={this.props.selected}
          title={this.props.title}
          titleIcon={this.props.titleIcon}
          tableDidFinishLoading={this.props.tableDidFinishLoading}
          tableDidStartLoading={this.props.tableDidStartLoading}
          getTableDataForSelectedRows={this.props.getTableDataForSelectedRows}
          isCsvAvailable={this.props.isCsvAvailable}
          cookies={this.props.cookies}
          model={this.props.model}
          isDeleteAvailable={this.props.isDeleteAvailable}
          isUpdateAvailable={this.props.isUpdateAvailable}
          updateButtonOnClick={this.props.updateButtonOnClick}
          isCreateAvailable={this.props.isCreateAvailable}
          createButtonOnClick={this.props.createButtonOnClick}
          viewButtonOnClick={this.props.viewButtonOnClick}
          isViewAvailable={this.props.isViewAvailable}
          customButton1={this.props.customButton1}
        />
      );
    }
  }
}

export default Toolbar;
