import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import StyledComponent from 'styled-components';

import { NotificationUtility } from '.';

const Layout2 = ({  containerRef,
                    notificationRef,
                    scrollRef,
                    unreadCount,
                    toggleShow,
                    styles,
                    showing,
                    notifications,
                    forward,
                    markRead  }) =>
{
  const cardList = Array.isArray(notifications) && notifications.map( (item) =>
  (
    <div
      key={item._id}
      onClick={() => forward('/view/notification/' + NotificationUtility.base64EncodeUnicode(item._id.toString()) + '/')}
    >
      <CDropdownItem><CIcon name="cil-user-follow" className="mr-2 text-success" />{` ${NotificationUtility.parseNotificationBody(item)}`}</CDropdownItem>
    </div>
  ));

  return (
    <_css>
      <CDropdown
        inNav
        className="c-header-nav-item mx-2"
      >
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <CIcon name="cil-bell"/>
          <CBadge shape="pill" color="danger">{unreadCount}</CBadge>
        </CDropdownToggle>
        <CDropdownMenu  placement="bottom-end" className="pt-0 items">
          <CDropdownItem
            header
            tag="div"
            className="text-center"
            color="light"
          >
            <strong>You have {unreadCount} unread notifications</strong>
          </CDropdownItem>
          {cardList}
        </CDropdownMenu>
      </CDropdown>
    </_css>
  )
}

const _css = StyledComponent.div`
  .items
  {
    height: 400px;
    overflow-y: auto;
  }
`;

export default Layout2
