import React from 'react';
import TextField from '@material-ui/core/TextField';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

/**
    @param  filters   table headers that should be treated as filterable columns
    @param  onChange  function that takes in the filter id and the event for the text on change
*/
const Filter = ({ layout,
                  filters,
                  filterValues,
                  onChange,
                  disabled,
                  selectEnabled }) =>
{
  if(layout === 1)
  {
    return (
      <Layout1
        filters={filters}
        filterValues={filterValues}
        onChange={onChange}
        disabled={disabled}
        selectEnabled={selectEnabled}
      />
    );
  }
  else
  {
    return (
      <Layout2
        filters={filters}
        filterValues={filterValues}
        onChange={onChange}
        disabled={disabled}
        selectEnabled={selectEnabled}
      />
    );
  }
}

export default Filter;
