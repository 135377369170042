import React from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

export default class LocationSearchInput extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state =
    {
      address: props.location.address ? `${props.location.address}, ${props.location.city}, ${props.location.state}, ${props.location.country}` : ''
    };
    console.log(props);
  }

  handleChange = address =>
  {
    this.setState({ address: address });
  };

  handleSelect = async(address, placeId, suggestion) =>
  {
    this.setState({ address: address });
    const geocodeResult = await geocodeByPlaceId(placeId);
    const latLng = await getLatLng(geocodeResult[0]);
    console.log(geocodeResult);
    console.log(latLng);

    let parsedAddress = '';
    let city = '';
    let state = '';
    let country = '';
    let zip = '';

    for(let i = 0; i < geocodeResult[0].address_components.length; i++)
    {
      for(let j = 0; j < geocodeResult[0].address_components[i].types.length; j++)
      {
        switch(geocodeResult[0].address_components[i].types[j])
        {
          case 'street_number':
            parsedAddress = geocodeResult[0].address_components[i].long_name;
            break;
          case 'route':
            parsedAddress += (' ' + geocodeResult[0].address_components[i].long_name);
            break;
          case 'locality':
            city = geocodeResult[0].address_components[i].long_name;
            break;
          case 'administrative_area_level_1':
            state = geocodeResult[0].address_components[i].short_name;
            break;
          case 'country':
            country = geocodeResult[0].address_components[i].short_name;
            break;
          case 'postal_code':
            zip = geocodeResult[0].address_components[i].short_name;
            break;
          default:
            break;
        }
      }
    }

    if(this.props.useLocationModel)
    {
      const location =
      {
        address: parsedAddress,
        city: city,
        state: state,
        zip: zip,
        country: country,
        point:
        {
          coordinates: [
            latLng.lng,
            latLng.lat
          ],
          type: "Point"
        }
      };
      if(this.props.location._id)
      {
        location._id = this.props.location._id;
      }
      this.formInputOnChange('location', location, 'location');
    }
    else
    {
      if(parsedAddress)
      {
        this.formInputOnChange('address', parsedAddress, 'string');
      }
      if(city)
      {
        this.formInputOnChange('city', city, 'string');
      }
      if(state)
      {
        this.formInputOnChange('state', state, 'string');
      }
      if(zip)
      {
        this.formInputOnChange('zip', zip, 'string');
      }
      if(country)
      {
        this.formInputOnChange('country', country, 'string');
      }
      this.formInputOnChange('location',
      {
        coordinates: [
          latLng.lng,
          latLng.lat
        ],
        type: "Point"
      },
      'point');
    }
  };

  formInputOnChange(id, val, type)
  {
    var change =
    {
      id:         id,
      value:      val,
      validation: null,
      type:       type,
      forcedChange: true,
    };
    this.props.formInputOnChange(change);
  }

  render()
  {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
        (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) =>
              {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={`autocomplete-result-${i}`}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
