import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Form } from 'react-bootstrap';
import { Datetime } from '../../utils';

import AppText from '../../constant/text';

export const ContextMenu = ({ closeMenu,
                              isEditMode,
                              editOnClick,
                              deleteOnClick,
                              reportOnClick,
                              cancelEditOnClick,
                              canEdit,
                              canReport,
                              anchorElement,
                              isOpen }) =>
{
  return (
  <div>
    <Menu
      id="simple-menu"
      anchorEl={anchorElement}
      keepMounted
      open={isOpen}
      onClose={() =>
      {
        closeMenu();
      }}
      >
        {canEdit && isEditMode &&
        <MenuItem onClick={cancelEditOnClick}>Cancel</MenuItem>}

        {canEdit && !isEditMode &&
        <MenuItem onClick={editOnClick}>Edit</MenuItem>}

        {canEdit && !isEditMode &&
        <MenuItem onClick={deleteOnClick}>Delete</MenuItem>}

        {canReport &&
        <MenuItem onClick={reportOnClick}>Report</MenuItem>}
    </Menu>
  </div>);
}

export default ContextMenu;
