import ApiRequest from '../api/request';

export default class NotificationManager
{
  static #instance = null;

  // User's notifications
  #notifications = [];
  #observers = [];

  /**
    Singleton accessor
    @param  {String}  apiToken   So we cna interact with API
    @returns {NotificationManager}
   */
  static async GetInstanceA(apiToken)
  {
    // Initialize
    if(NotificationManager.#instance == null)
    {
      NotificationManager.#instance = new NotificationManager();

      // Get details from backend
      await NotificationManager.#instance.init(apiToken);
    }
    return NotificationManager.#instance;
  }

  /**
     Singleton accessor
    @returns {NotificationManager}
   */
  static GetInstance()
  {
    return NotificationManager.#instance;
  }


  // Retrieve information from backend
  async init(apiToken)
  {
    console.log('\t\tNotificationManager.init()');
    if(!apiToken)
    {
      this.#notifications = [];
      return;
    }
    try
    {
      var response = await ApiRequest.sendRequest("post", {}, "notification/init", apiToken);
      if(response.data.error !== null)
      {
        console.error('NotificationManager.init error: ' + response.data.error);
        return false;
      }
      console.log(response.data.results);
      this.#notifications = response.data.results;
      return true;
    }
    catch(err)
    {
      console.log('NotificationManager.init error: ' + err + '\nError stack: ' + err.stack);
      return false;
    }
  }

  /**
    Add new notification and notify observers
    @param  {Notification}  notification  New notification to add
  */
  newNotification(notification)
  {
    this.#notifications.unshift(notification);
    for(let i = 0; i < this.#observers.length; i++)
    {
      if(typeof this.#observers[i].newNotification === "function")
      {
        this.#observers[i].newNotification(notification);
      }
    }
  }

  /**
    Get notifications
  */
  getNotifications()
  {
    return this.#notifications;
  }

  /**
    Set notifications
    @param  {Array.<Notification>}  notifications   Notifications
  */
  setNotifications(notifications)
  {
    this.#notifications = notifications;
    this.dataReloaded();
  }

  /**
    Mark notification as read internally only
  */
  markRead(notification)
  {
    console.log('\t\tNotificationManager.markRead()');
    // Find notification and mark it read
    let dataChanged = false;
    for(let i = 0; i < this.#notifications.length; i++)
    {
      if(this.#notifications[i]._id.toString() === notification._id.toString())
      {
        dataChanged = true;
        this.#notifications[i].status = 'read';
        break;
      }
    }

    // Notify observers
    if(dataChanged)
    {
      this.dataReloaded();
    }
  }


  /**
    Notify observers that data reloaded
  */
  dataReloaded()
  {
    console.log('\t\tNotificationManager.dataReloaded()');
    for(let i = 0; i < this.#observers.length; i++)
    {
      if(typeof this.#observers[i].dataReloaded === "function")
      {
        this.#observers[i].dataReloaded();
      }
    }
  }

  /**
    Add new observer to list
  */
  addObserver(observer)
  {
    this.#observers.push(observer);
  }
}
