import { NotificationUtility } from '../components/notifications';

export function parseQueryString()
{
  var str = window.location.search;
  var objURL = {};

  str.replace(
    new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
    function( $0, $1, $2, $3 )
    {
      if(isBase64($3))
      {
        objURL[$1] = NotificationUtility.base64Decode($3);
      }
      else
      {
        objURL[$1] = $3;
      }
    }
  );
  return objURL;
};

function isBase64(str)
{
  try 
  {
    window.atob(str);
    return true;
  }
  catch(e)
  {
    return false;
  }
}

export default {
  parseQueryString: parseQueryString
};
