import React from 'react';
import { withRouter } from 'react-router'

import { ApiManager, DataManager } from '../../../managers';
import { SetActiveAppCommand } from '../../../command/data';

import Layout1 from './layout-1';
import Layout2 from './layout-2';

const COMPONENT_NAME = 'navigationBar';

class NavigationBar extends React.Component
{
  _layout = 2;
  _dataMgr = null;

  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tNavigationBar()");
    super(props);
    this.state =
    {
      component:
      {
        navData: [],
        dynamicData: (this._layout === 1)
      },

      dataVersion: 0, // Allow data manager to update us using this
    };

    this._dataMgr = DataManager.GetInstance();
  }

  async componentDidMount()
  {
    console.log('\tNavigationBar.componentDidMount()');
    this._isMounted = true;

    // Setup data manager listener to detect when app changes
    this._dataMgr.addObserver(COMPONENT_NAME,
    () =>
    {
      this.setState({ dataVersion: this.state.dataVersion + 1 });
    },
    'app');

    // Load data
    if(this.props.cookies.get('token') && this._layout === 1)
    {
      await this.loadData();
    }
  }

  componentWillMount()
  {
    this._dataMgr.removeObserver(COMPONENT_NAME);
  }

  loadData = async() =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'component',
      action: 'side-nav'
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    const component = {...this.state.component};
    if(this.state.component.dynamicData)
    {
      component.navData = response.results;
    }

    this.setState({ component: component });
    this.props.updateMasterState({ isLoading: false });
  }


  // MARK: - Onchange handlers
  navOnChange = (selectedNavItem) =>
  {
    console.log(selectedNavItem);

    if(this.props.isMobile &&
      this.props.selectedNavItem !== selectedNavItem &&
      selectedNavItem !== '/logout' &&
      this.props.location.pathname !== 'home')
    {
      this.props.updateMasterState({ selectedNavItem: selectedNavItem }, () =>
      {
        this.props.history.push('/home');
      });
    }

    if(this.props.selectedNavItem !== selectedNavItem)
    {
      this.props.history.push('/' + selectedNavItem.toLowerCase().replace('/', ''));
      this.props.updateMasterState({ selectedNavItem: selectedNavItem })
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // If manually clearing active nav item call prop onChange
    return (
      this.state.dataVersion !== nextState.dataVersion ||
      this.props.navListIsShowing !== nextProps.navListIsShowing ||
      this.props.loggedIn !== nextProps.loggedIn ||
      this.props.isMobile !== nextProps.isMobile ||
      this.state.navData !== nextState.navData
    );
  }

  // MARK: - Render
  render()
  {
    console.log('NavigationBar.render()');

    const app = this._dataMgr.getData('app');

    if(this._layout === 1)
    {
      return (
        <Layout1
          updateMasterState={this.props.updateMasterState}
          history={this.props.history}
          title={this.props.title}
          navOnChange={this.navOnChange}
          loggedIn={this.props.loggedIn}
          cookies={this.props.cookies}
          notificationManager={this.props.notificationManager}
          siteManager={this.props.siteManager}
          forward={this.props.forward}
          isMobile={this.props.isMobile}
          component={this.state.component}
          path={this.props.path}
          navListIsShowing={this.props.navListIsShowing}
        />
      );
    }
    else
    {
      return (
        <Layout2
          updateMasterState={this.props.updateMasterState}
          history={this.props.history}
          title={this.props.title}
          navOnChange={this.navOnChange}
          loggedIn={this.props.loggedIn}
          cookies={this.props.cookies}
          notificationManager={this.props.notificationManager}
          siteManager={this.props.siteManager}
          forward={this.props.forward}
          isMobile={this.props.isMobile}
          component={this.state.component}
          path={this.props.path}
          navListIsShowing={this.props.navListIsShowing}
          activeApp={app.active}
          setActiveApp={async(app) =>
          {
            return await this._dataMgr.execute(await new SetActiveAppCommand(
            {
              setIsLoading: (isLoading) => this.props.updateMasterState({ isLoading: isLoading }),
              activeApp: app
            }));
          }}
        />
      );
    }
  }
}

export default withRouter(NavigationBar);
