import React from 'react';

import StyledComponent from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { Nav } from 'react-bootstrap';

import { NotificationUtility } from '../../notifications';

const Layout1 = ({  user,
                    name,
                    navData,
                    active,
                    navOnChange }) =>
{
  return (
    <_css>
      <Paper elevation={3} variant='outlined'>
        <Avatar
          alt="Profile image"
          src={user.photo ? user.photo : require('../../../asset/no-photo.png')}
          className={'avatar'}
          onClick={() =>
          {
            const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(user._id.toString()) + '/';
            //this.props.updateMasterState({ redirect: redirect })
          }}
        />
        {name.length > 1 &&
        <p
          className='name'
          onClick={() =>
          {
            const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(user._id.toString()) + '/';
            //this.props.updateMasterState({ redirect: redirect })
          }}
        >{name}</p>}
        <Nav
          activeKey={`${active}`}
          className="navlist-container"
          onSelect={e => navOnChange(e)}>
            {navData && navData.map( (item, i) =>
              <Nav.Link
                key={i}
                eventKey={item.title}
              >
                {item.icon.indexOf('.png') === -1 ? <span className={item.icon}/>
                                                  : <img className={'link-img'} src={require('../../../asset/' + item.icon)} />}
                {item.displayName}
              </Nav.Link>
            )}
        </Nav>
      </Paper>
    </_css>
  );
}

const _css = StyledComponent.div`
  a, .navbar-brand, .navbar-nav .nav-link
  {
    &:hover
    {
    color: black;
    }

    span
    {
      padding-right: 5px;
    }

    inline-size: fit-content;
    padding-right: 18px;
    color: #007BF9;
  }
  .nav-link
  {
    text-align: center;
    padding: 0.2rem 0.5rem;
    align-self: center;
  }
  .link-img
  {
    width: 30px;
    margin-right: 10px;
  }

  .active
  {
    color: #FFFFFF;
    background: #000;
    border-radius: 10px;

    &:hover
    {
      color: #FFF;
    }
  }

  .name
  {
    text-align: center;
    color: #007BF9;
    font-weight: 600;
  }

  .avatar
  {

    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 5px;
    border: 3px solid #cecfd1;
  }

  .navlist-container
  {
    flex-direction: column!important;
    @media only screen and (max-width: 992px)
    {
      flex-direction: row!important;
      justify-content: center;
    }
  }

  background: #FFF;
  height: 100%;
  position: fixed;
  z-index: 0;
  /*top: 0;*/
  left: 0;
  overflow-x: hidden;
  display: inline-block;

  @media only screen and (max-width: 992px)
  {
    width: 100%
    height: fit-content;
    position: unset;
  }
  @media only screen and (min-width: 993px)
  {
    width: 100%
    height: fit-content;
    position: unset;
  }
`;

export default Layout1;
