import React from 'react';

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavItem,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { Images } from '../../../constant';

// sidebar nav config
const nav =  [
  /* Users */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Users']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'All Users',
    to: '/users',
    icon: 'cil-user',
  },
  /* Scans */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Scans']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Scans',
    to: '/scans',
    icon: 'cil-map',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Insert',
    to: '/insert',
    icon: 'cil-file',
  },
  /* Establishments */
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Establishments']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Establishments',
    to: '/establishments',
    icon: 'cil-home',
  },
  // CMS 
  {
    _tag: 'CSidebarNavTitle',
    _children: ['CMS']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Terms and conditions',
    to: '/edit-tos',
    icon: 'cil-notes',
  },
];

const Layout2 = ({  user,
                    name,
                    navOnChange,
                    isShowing,
                    active,
                    setIsShowing,
                    activeApp }) =>
{
  return (
    <CSidebar
      show={isShowing}
      onShowChange={(val) => setIsShowing(val)}
    >
      <CSidebarBrand className="d-md-down-none" to="/home">
        <img
          className="c-sidebar-brand-full"
          style={{height: '35px'}}
          src={Images.appIcon}
        />
        <img
          className="c-sidebar-brand-minimized"
          style={{height: '35px'}}
          src={Images.appIcon}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default Layout2;
