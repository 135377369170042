import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Post, PostHeader, CreatePostDialog } from '.';
import AppText from '../../constant/text';
import { ApiManager } from '../../managers';

export default class Container extends React.Component
{
  _isMounted = false;


  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tPosts()");
    super(props);

    this.state =
    {
      defaultComponent: props.component
    };
  }

  async componentDidMount()
  {
    console.log('\tPosts.componentDidMount()');
    this._isMounted = true;

    // Fetch data
    this.props.updateMasterState({ isLoading: true });
    const response = await ApiManager.SendRequest(
    {
      route: 'component',
      action: 'posts'
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    const component = {...this.props.component};
    if(this.props.dynamicData !== false)
    {
      component.details = response.results;
    }
    const updateParams =
    {
      postsComponent: component,
      isLoading: false
    }
    this.props.updateMasterState(updateParams);
  }

  // MARK: - API related
  /**
    Comment on a document
    @param  {JSON}  params  Create params
    @param  {Int}  index   Index this comment is at
    @param  {Array.<Comment>} reverseOrderParents   The reverse order of parents so we can easily traverse back
    @param  {Int} depth   The depth of this comment on parent
  */
  comment = async(id, msg) =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });

    const component = {...this.props.component};
    const idx = component.details.findIndex(detail => detail._id.toString() === id.toString());

    const response = await ApiManager.SendRequest(
    {
      route: 'data',
      action: 'create',
      params:
      {
        message: msg,
        entityType: 'post',
        entityId: id,
        parent: '_null_',
        likedBy: '_empty_array_',
        dislikedBy: '_empty_array_'
      },
      model: 'comment'
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    component.details[idx].comments.push(response.results);
    const updateParams =
    {
      postsComponent: component,
      isLoading: false
    }
    this.props.updateMasterState(updateParams);
    return response.results;
  }

  /**
    Delete a document for particular model
    @note Parameters come in an object named params
    @param {String}  params.model is the model type being created
    @param  {JSON}  params.post   {id: postId}
    @param  {JSON}    params.comment  {id: commentId, message: commentText} (Optional if updating post)
  */
  delete = async(params) =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });

    const component = {...this.props.component};
    const postIdx = component.details.findIndex(detail => detail._id.toString() === params.post.id.toString());

    const response = await ApiManager.SendRequest(
    {
      route: 'data',
      action: 'delete',
      model: params.model,
      id: (params.comment ? params.comment.id : params.post.id)
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    if(params.comment)
    {
      const commentIdx = component.details[postIdx].comments.findIndex(comment => comment._id.toString() === params.comment.id.toString());
      component.details[postIdx].comments.splice(commentIdx, 1);
    }
    else
    {
      component.details.splice(postIdx, 1);
    }

    this.props.updateMasterState({ isLoading: false, postsComponent: component });
  }

  /**
    Like a document for particular model
    @param  {String}  id  The id of the document
    @param  {String}  model   The model document belongs to
  */
  like = async(id, model) =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });

    const component = {...this.props.component};
    const idx = component.details.findIndex(detail => detail._id.toString() === id.toString());

    const response = await ApiManager.SendRequest(
    {
      route: 'social',
      action: 'like',
      model: 'post',
      id: id
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    const oldPost = component.details[idx];

    // TODO: Add logic to only bring comments over in component has it enabled ^_^
    // Bring comments over manually (If enabled on component)
    component.details[idx] = response.results;
    component.details[idx].comments = oldPost.comments;
    const updateParams =
    {
      postsComponent: component,
      isLoading: false
    }
    this.props.updateMasterState(updateParams);
  }

  /**
    Report a document for particular model
    @param  {String}  id  The id of the document
    @param  {String}  model   The model document belongs to
  */
  report = async(id, model) =>
  {
    // Fetch data
    this.props.updateMasterState({ isLoading: true });

    const component = {...this.props.component};
    const idx = component.details.findIndex(detail => detail._id.toString() === id.toString());

    const response = await ApiManager.SendRequest(
    {
      route: 'social',
      action: 'report',
      model: model,
      id: id
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    this.props.showAlert(true, 'Information', response.message, 'success');
    this.props.updateMasterState({ isLoading: false });
  }

  /**
    Update document
    @note Parameters come in an object named params
    @param {String}  params.model is the model type being created
    @param  {JSON}  params.post   {id: postId}
    @param  {JSON}    params.comment  {id: commentId, message: commentText} (Optional if updating post)
  */
  update = async(params) =>
  {
    this.props.updateMasterState({ isLoading: true });

    //
    const component = {...this.props.component};
    const postIdx = component.details.findIndex(detail => detail._id.toString() === params.post.id.toString());

    let updateParams = {};
    if(params.comment)
    {
      updateParams =
      {
        message: params.comment.message
      };
    }
    else
    {
      updateParams =
      {
        text: params.post.text
      }
    }

    const response = await ApiManager.SendRequest(
    {
      route: 'data',
      action: 'update',
      model: params.model,
      id: params.comment ? params.comment.id : params.post.id,
      params: updateParams
    });
    if(response.error !== null)
    {
      this.props.updateMasterState({ isLoading: false });
      this.props.showAlert(true, 'Un-oh', response.error, 'danger');
      return;
    }

    // Update parent
    if(params.comment)
    {
      const commentIdx = component.details[postIdx].comments.findIndex(comment => comment._id.toString() === params.comment.id.toString());
      component.details[postIdx].comments[commentIdx] = response.results;
      console.log(component.details[postIdx].comments[commentIdx]);
    }
    else
    {
      const oldPost = component.details[postIdx];
      component.details[postIdx] = response.results;
      component.details[postIdx].comments = oldPost.comments;
    }
    updateParams =
    {
      postsComponent: component,
      isLoading: false
    }

    this.props.updateMasterState(updateParams);
    return response.results;
  }

  // MARK: - Render
  shouldComponentUpdate(nextProps, nextState)
	{
    return (
      this.props.user !== nextProps.user ||
      this.props.component.create.isOpen !== nextProps.component.create.isOpen ||
      this.props.component.create.detail.text !== nextProps.component.create.detail.text ||
      this.props.component.create.detail.files !== nextProps.component.create.detail.files ||
      this.props.component.details !== nextProps.component.details ||
      this.props.siteManager !== nextProps.siteManager
    );
	}

  render()
  {
    console.log('\tPosts.render()');
    return (
      <Paper>

        {this.props.component !== null &&
          this.props.component.create &&
        <CreatePostDialog
          key='post-dialog'
          siteManager={this.props.siteManager}
          title={AppText.posts.create.title}
          textTitle={AppText.posts.create.text}
          filesTitle={AppText.posts.create.files}
          imgPath={'post-header.png'}
          onClose={() =>
          {
            const postsComponent = {...this.props.component};
            postsComponent.create.isOpen = false;
            this.props.updateMasterState({ postsComponent: postsComponent });
          }}
          create={async() =>
					{
						this.props.updateMasterState({ isLoading: true });
						const response = await ApiManager.SendRequest(
						{
							route: 'data',
							action: 'create',
							params: this.props.component.create.detail,
							model: 'post'
						});
						if(response.error !== null)
						{
              this.props.updateMasterState({ isLoading: false });
							this.props.showAlert(true, 'Un-oh', response.error, 'danger');
							return;
						}
						const component = {...this.state.defaultComponent};
						const newDetails = [response.results, ...this.props.component.details];
            component.details = [response.results, ...this.props.component.details];
            component.create.isOpen = false;
            this.props.updateMasterState({ isLoading: false, postsComponent: component });
						//this.props.showAlert(true, 'Success', response.message, 'success');
					}}
          updateActiveTab={(activeTab) =>
          {
            const component = {...this.props.component};
            component.create.activeTab = activeTab;
            this.props.updateMasterState({ postsComponent: component });
          }}
          updateNewPost={(type, value) =>
          {
            const component = {...this.props.component};
            component.create.detail[type] = value;
            this.props.updateMasterState({ postsComponent: component });
          }}
          component={this.props.component.create}
        />}

        {this.props.component !== null &&
          this.props.component.create &&
        <PostHeader
          key='post-header'
          logo={'post-header.png'}
          title={AppText.posts.header.title}
          description={AppText.posts.header.description}
          siteManager={this.props.siteManager}
          postOnClick={() =>
          {
            const component = {...this.props.component};
            const create = {...component.create};
            create.isOpen = true;
            component.create = create;
            this.props.updateMasterState({ postsComponent: component });
          }}
        />}

        {this.props.component !== null &&
          this.props.component.details &&
          this.props.component.details.map( (post, i) =>
        {
          if(i % 2 === 0)
          {
            const secondPost = this.props.component.details[i+1];
            return (
              <div className='row' key={`post-row-${i}`}>
                <div className='col-lg-1'/>
                <div className='col-lg-5'>
                  <Post
                    component={this.props.component}
                    post={post}
                    updatePost={(postText) => this.update({ post: { id: post._id, text: postText }, model: 'post' })}
                    deletePost={() => this.delete({ post: { id: post._id }, model: 'post' })}
                    reportPost={() => this.report(post._id, 'post')}
                    like={() => this.like(post._id, 'post')}
                    updateComment={(commentId, commentText) => this.update({ post: { id: post._id }, model: 'comment', comment: { id: commentId, message: commentText } })}
                    deleteComment={(commentId) => this.delete({ post: { id: post._id }, model: 'comment', comment: { id: commentId } })}
                    comment={this.comment}
                    user={this.props.user}
                    siteManager={this.props.siteManager}
                    updateMasterState={this.props.updateMasterState}
                  />
                </div>
                {secondPost &&
                <div className='col-lg-5'>
                  <Post
                    component={this.props.component}
                    post={secondPost}
                    updatePost={(postText) => this.update({ post: { id: post._id, text: postText }, model: 'post' })}
                    deletePost={() => this.delete({ post: { id: secondPost._id }, model: 'post' })}
                    reportPost={() => this.report(secondPost._id, 'post')}
                    like={() => this.like(secondPost._id, 'post')}
                    updateComment={(commentId, commentText) => this.update({ post: { id: secondPost._id }, model: 'comment', comment: { id: commentId, message: commentText } })}
                    deleteComment={(commentId) => this.delete({ post: { id: secondPost._id }, model: 'comment', comment: { id: commentId } })}
                    comment={this.comment}
                    user={this.props.user}
                    siteManager={this.props.siteManager}
                    updateMasterState={this.props.updateMasterState}
                  />
                </div>}
                <div className='col-lg-1'/>
              </div>
            );
          }
        })}
      </Paper>
    );
  }
}
