import { extractValueFromPointer } from '../Form/utility';

/**
  Parse out notification body and substitute pointers with actual values
  @param  {Notification}  notification  The notification to parse
  @returns  {String}  parsed body
*/
function parseNotificationBody(notification)
{
  let parsedBody = '';
  let processingBody = notification.body;
  let index = processingBody.indexOf('{{');
  let ptrValue = '';
  while(index !== -1)
  {
    if(index !== 0)
    {
      parsedBody += processingBody.substr(0, index);
    }
    ptrValue = processingBody.substr(index + 2, processingBody.indexOf('}}') - 2);
    processingBody = processingBody.substr(processingBody.indexOf('}}'), processingBody.length);
    parsedBody += extractValueFromPointer(ptrValue, notification);
    index = processingBody.indexOf('{{');
  }
  if(processingBody.length > 0)
  {
    parsedBody += processingBody.replace('}}', '');
  }
  return parsedBody;
}

function base64EncodeUnicode(str)
{
    // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
    // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
    let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
    });

    return btoa(utf8Bytes);
}

function base64Decode(str)
{
  return new Buffer(str, 'base64').toString('ascii');
}

export default { parseNotificationBody, base64EncodeUnicode, base64Decode };
