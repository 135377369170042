import React from 'react';
import PropTypes from 'prop-types';
import {  Avatar,
          Button,
          Card,
          CardActions,
          CardContent,
          Typography } from '@material-ui/core';

import { AppText } from '../../../constant';
import { Datetime } from '../../../utils';
import { NotificationUtility } from '..';

const Notification = ({ item,
                        markRead,
                        onClick }) =>
{
  // Parse out body
  let parsedBody = NotificationUtility.parseNotificationBody(item);

  return (
    <Card
      className={'card'}
      onClick={() => onClick('/view/notification/' + NotificationUtility.base64EncodeUnicode(item._id.toString()) + '/')}
      style={{backgroundColor: item.status === 'read' ? '#ebedf0' : '#FFF'}}
    >
      <CardContent className={'content'}>
        <Avatar
          alt="Profile image"
          src={item.createdBy.photo}
          className={'notification-avatar'}
        />
        <Typography
          variant="body2"
          paragraph={true}
          align='left'
          className='notification-body'
        >{parsedBody}
        </Typography>
      </CardContent>
      <CardActions className='date-and-options'>
        <Typography
          variant="subtitle2"
          classes={{subtitle2: 'notification-date'}}
          align={'left'}
          display='block'
        >
          {Datetime.formatFullDate(item.createdOn)}
        </Typography>
        {item.status !== 'read' &&
        <Button
          className={'options'}
          size="small"
          onClick={() => markRead(item._id)}
        >{AppText.navigation.notifications.item.clear}</Button>}
      </CardActions>
    </Card>
  );
};

Notification.defaultProps =
{
  markRead: null,
  data: null,
};

Notification.propTypes =
{
  item: PropTypes.shape(
  {
    createdBy: PropTypes.object,
    body: PropTypes.string,
    receivedTime: PropTypes.string,
    _id: PropTypes.string,
  }),
  markRead: PropTypes.func,
  classNamePrefix: PropTypes.string
};
export default Notification;
