import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import Marker from './marker';

function Requirement(props)
{
    var className = 'requirement' + (props.isHidden === true ? '' : '-showing');
    return (<div className={className}>{props.text}</div>);
}


/**
  Input field for validated form
  @param  {function}    isFormValid         Callback to form so we can check if entire form is valid
  @param 	{function} 	  formInputOnChange   Callback when a form value changes
  @param  {String}        rowClassName      Class name of forminput container (used for creating new rows and stuff)
  @param  {bool}        errorMessage        Indicates if form field is in error state (empty if not) otherwise holds message
  @param	{JSON}	     data                 JSON object representing form input
    label: 		 Label text to show above input
    id:			   ID of inputs so it can be accessed by parent later
    type: 		 Type of input (text/password/email/submit)
    element: 	 The element type (input/textarea/select)
    requireValidForm  Only available on submit types to control if they can be used if the form is in an invalid state {default=true}
    options:   Only available for element of type select
    placeholder: Placeholder text to display {default=""}
    value:       Value
    onClick:     Onclick javascript handler
    required:    Required to submit form HTML5 validation (required) (default="")
    validation 	JSON object with validation algorithm return true/false
    algorithm: email|range
    params: [range: min,max]
  State:
   @param  {bool}    showRequiredDiv       If the field is in focus and error
   @param  {bool}    isInFocus             If the field is in focus or not
   @param  {bool}    isDisabled            If the field is disabled or not
   @param  {string}  value                 The value in the field (user input)
 */
 export default class GoogleMap extends React.Component
 {
   _circle = null;
   _mapRef = null;
   _mapsRef = null;

     // MARK: - Constructor
     constructor(props)
     {
       console.log('Map()');
       super(props);

       let value = props.value;
       if(props.data.type === 'checkbox')
       {
         value = props.data.checked;
       }
       else if(props.data.type === 'select' && props.data.multiSelectEnabled)
       {
         value = Array.isArray(props.data.value) ? props.data.value : [props.data.value];
       }
       // Need to parse string to array of coordinates
       else if(props.data.type === 'point' &&
       !props.data.value.coordinates &&
       props.data.value)
       {
         const location = JSON.parse(props.data.value);
         value =
         {
           coordinates: [
             location[0],
             location[1]
           ],
           type: "Point"
         };
       }

       this.state =
       {
         showRequiredDiv:   false,
         isInFocus:         false,
         isDisabled:        props.data.disabled,
         value:             value,
         isMapDraggable:    true,
         locationLoading:   false,
         radius:            props.data.radius ? props.data.radius : 500,
         byPassRadiusUpdate: false,
       };

       //console.log("FormInput(" + props.data.id + ")");
     }

    async componentDidMount()
    {
      // If creating a new location then value is not supplied
      // use user's current location
      if(!this.state.value)
      {
        this.getCurrentLocation();
      }
    }

    // MARK: - Event handlers
    // Update parent
    onChange(lat, lng)
    {
      const value = {...this.state.value};
      value.coordinates[1] = lat;
      value.coordinates[0] = lng;

      //console.log(value);
      this.setState({ value: value });

      var change =
      {
        id:         this.props.data.id,
        value:      value,
        validation: this.props.data.validation,
        type:       this.props.data.type
      };

      //console.log(change);

      // Notify form (parent)
      this.props.formInputOnChange(change);
    }

    radiusOnChange(radius)
    {

      var change =
      {
        id:         'radius',
        value:      radius,
        validation: this.props.data.validation,
        type:       'number',
        forcedRadiusChange: true,
      };

      this.setState({ radius: radius });

      // Avoid infinite loop if using radius field to modify radius rather than dragging circle
      if(!this.state.byPassRadiusUpdate)
      {
        // Notify form (parent)
        console.log('Updating radius');
        this.props.formInputOnChange(change);
      }
      else
      {
        this.setState({ byPassRadiusUpdate: false });
      }
    }

    /**
      *     Form will call this back after it validates itself.
      *     @param  {bool}  isValid     If the parent/form is valid or not
      */
    formStateChanged(isValid)
    {
      //console.log('\t\tFormInput(' + this.props.data.id + ').formStateChanged(isValid=' + isValid + ')');

      // If we are a submit button we will enable/disable ourselves
      if(this.props.data.type && (this.props.data.type === 'submit' || this.props.data.type === 'button'))
      {
        // If the button does not care about the form being valid we will tell it's valid
        // this way the user can use it (to cancel for example)
        if(this.props.data.requireValidForm === false)
        {
          this.setState({ isDisabled: false });
        }
        else
        {
          this.setState({ isDisabled: !isValid });
        }
      }
    }

    onFocus = () =>
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').onFocus()');
        this.setState({ isInFocus: true });
    }
    onFocusOut = () =>
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').onFocusOut()');
        this.setState({ showRequiredDiv: false, isInFocus: false });
    }

    // MARK: - Helpers
    async updateValue(value)
    {
      try
      {
        await this.setStateAsync({value: value, byPassRadiusUpdate: true });
        console.log("Finished updating state " + this.props.data.id + ' to value: ' + JSON.stringify(value));
        if(value.radius !== undefined && value.radius !== '')
        {
          this._circle.setRadius(parseFloat(value.radius));
        }
        return true;
      }
      catch(err)
      {
        console.log(err);
        console.log(JSON.stringify(value));
      }
    }

    setStateAsync(state)
    {
      return new Promise((resolve) =>
      {
          this.setState(state, resolve)
      });
    }

    getCurrentLocation = async() =>
    {
      console.log('getCurrentLocation()');
      if ("geolocation" in navigator)
      {
        this.setState({ locationLoading: true });
        navigator.geolocation.getCurrentPosition((position) =>
        {
          this.setState({
            value:
            {
              coordinates: [
                position.coords.longitude,
                position.coords.latitude
              ],
              type: "Point"
            },
            locationLoading: false,
          });
        });
      }
    }

    // MARK: - Marker related
    onMarkerMove = (childKey, childProps, mouse) =>
    {
      //this.onChange(mouse.lat, mouse.lng);
    }

    apiIsLoaded = (map, maps) =>
    {
      this._mapRef = map;
      this._mapsRef = maps;
      this._circle = new this._mapsRef.Circle({
        strokeColor: "001e57",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#bbd0ff",
        fillOpacity: 0.3,
        map: this._mapRef,
        center: {  lat: this.state.value.coordinates[1], lng: this.state.value.coordinates[0] },
        radius: parseFloat(this.state.radius),
        editable: true,
        draggable: false,
      });
      this._mapsRef.event.addListener(this._circle, 'radius_changed', () =>
      {
        this.radiusOnChange(this._circle.getRadius());
      });
      this._mapsRef.event.addListener(this._circle, 'center_changed', () =>
      {
        this.onChange(this._circle.getCenter().lat(), this._circle.getCenter().lng());
      });
    };

	   // MARK: - Render
    shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if value changed, disabled state changed, or requirement div hidden/shown or focus changed or form is resetting
        return (this.state.value !== nextState.value ||
                this.state.isDisabled !== nextState.isDisabled ||
                this.props.errorMessage !== nextProps.errorMessage ||
                this.state.isInFocus !== nextState.isInFocus ||
                this.state.tooltipOpen !== nextState.tooltipOpen ||
                this.state.radius !== nextState.radius);
    }

    render()
    {
      console.log(this.props.data);
      console.log(this.state.value);
      if(this.props.data.multiSelectEnabled || this.props.data.id === 'managedUpdateForm')
      {
        //console.log('\t\tFormInput(' + this.props.data.id + ').render()\n\tProp value ' + this.props.data.value + '\n\tState value ' + this.state.value);
        //console.log(this.props);
      }
      //console.log(this.props.data);
      //console.log(this.props.data.id + '- ErrorMessage: ' + this.props.errorMessage);

      // Label and tooltip
      var label = '';
      if(this.props.data.label)
      {
        label = (<label>{this.props.data.label}</label>);
      }

      // Requirement
      var requirement = (this.props.data.validation && this.props.data.validation.requirement ? this.props.data.validation.requirement : '')
      var requirementDivIsHidden = true;

      // Class name
      var className   = (this.props.data.class ? this.props.data.class : '');
      if(this.props.errorMessage === '' || this.props.errorMessage === undefined)
      {
        try
        {
          // No error and value input so show valid
          if(this.state.value && this.state.value.length > 0) // Strings
          {
              className += 'valid';
          }
          else if(this.state.value && !isNaN(this.state.value)) // Numbers
          {
            className += 'valid';
          }
          else if(this.state.value === 0 && this.props.data.type === 'number')
          {
            className += 'valid';
          }
          else
          {
          //  console.log(this.props.data.id + '? Curious functionality');
          }
        }
        catch(err)
        {
          console.log(err);
          console.log(this.props.data.id);
        }
      }
      else
      {
        // Show requirement div  if error message
        if(this.props.errorMessage !== '-EV')
        {
          // If field not in focus show it as pink
          if(!this.state.isInFocus)
          {
            className += 'invalid';
          }
          // Otherwise show requirement text
          else
          {
            requirementDivIsHidden = false;
          }
        }
      }

      // Required
      //var required    = (this.props.data.required ? this.props.data.required : '');
      //console.log(this.props.data);
      var onClick = (e) =>
      {
        if(this.props.data.onClick)
        {
          console.log('\t\tFormInput(' + this.props.data.id + ').onClick()');
          this.props.data.onClick(e);
        }
      };

      const formInput = (this.state.value ?
      <div style={{ height: '300px', width: '100%', marginTop: '30px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDNjMRvKb18pmXzQX4jCFD5u4XVJukWbNw' }}
          draggable={this.state.isMapDraggable}
          defaultCenter={
          {
            lat: this.state.value.coordinates[1],
            lng: this.state.value.coordinates[0],
          }}
          defaultZoom={11}
          onChildMouseDown={() => this.setState({ isMapDraggable: false })}
          onChildMouseUp={() =>
          {
            this.setState({ isMapDraggable: true });
          }}
          onChildMouseMove={this.onMarkerMove}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps}) => this.apiIsLoaded(map, maps)}
        >
          <Marker
            lat={this.state.value.coordinates[1]}
            lng={this.state.value.coordinates[0]}
            text="My Marker"
          />
        </GoogleMapReact>
      </div> : <p>{this.state.locationLoading ? ' - Loading...' : ' - Must be allowed for map to work'}</p>
    );

    // Controls if this input is an entire row to itself or not
    return (
		<div className={this.props.rowClassName}>

			{this.props.data.tooltip &&
        this.props.data.tooltipType === 'onlabel' &&
          <Tooltip
            title={`${label ? '' : this.props.data.tooltipQuestion + '\n'}${this.props.data.tooltip}`}
          >
            {label ? label : <p>{this.props.data.tooltipQuestion}</p>}
          </Tooltip>
      }

      {this.props.data.tooltip &&
        this.props.data.tooltipType === 'sidebutton' &&
          <div>
            {label}
            <p
              className='tooltip-question'
              onClick={ (e) => this.props.openTooltip({ message: this.props.data.tooltip, question: this.props.data.tooltipQuestion }) }
            >{this.props.data.tooltipQuestion}</p>
          </div>
      }

      {!this.props.data.tooltip && label}

			{formInput}
      <Requirement isHidden={requirementDivIsHidden}
          text={requirement}
      />
		</div>);
	}
}
