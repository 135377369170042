import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import StyledComponent from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Actions, Comments, ContextMenu } from '.';
import { Colors, Extensions } from '../../constant';
import { Button, Form } from 'react-bootstrap';
import { NotificationUtility } from '../notifications';
import { VideoPlayer } from '../videoPlayer';

import AppText from '../../constant/text';
import { Datetime } from '../../utils';

/**
*/
class Post extends React.Component
{
  _css = null;


  constructor(props)
  {
    console.log("\tPost()");
    super(props);

    this.state =
    {
      comments:
      {
        isShowing: false,
        text: '',
        isValid: false
      },
      contextMenuIsOpen: false,
      contextMenuAnchorElement: null,
      isEditMode: false,
      editText: '',
    };

    // Style component
    this._css = this.styleComponent(props.siteManager);
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    let newComment = (this.state.comments.length === nextState.comments.length);
    if(!newComment)
    {
      for(let i = 0; i < nextState.comments.length; i++)
      {
        if(this.state.comments[i].message !== nextState.comments[i].message)
        {
          newComment = true;
          break;
        }
      }
    }
    return (
      this.state.comments !== nextState.comments ||
      this.state.contextMenuIsOpen !== nextState.contextMenuIsOpen ||
      this.state.contextMenuAnchorElement !== nextState.contextMenuAnchorElement ||
      this.state.isEditMode !== nextState.isEditMode ||
      this.state.editText !== nextState.editText ||
      this.props.post !== nextProps.post ||
      newComment
    );
  }

  // TODO: In mongo need to create a text index on posts.text because the size is too large to index
  render()
  {
    let fileType = null;
    if(this.props.post.files.length > 0)
    {
      const extensionType = this.props.post.files[0].substr(this.props.post.files[0].lastIndexOf('.') + 1).toLowerCase();
      if(Extensions.image.indexOf(extensionType) !== -1)
      {
        fileType = 'image';
      }
      else if(Extensions.video.indexOf(extensionType) !== -1)
      {
        fileType = 'video';
      }
      else
      {
        fileType = 'unknown';
      }
    }

    return (
    <this._css>
    <Grid
      item
      className='post-container'
    >
      <Paper className='paper' elevation={3}>
        <Grid container direction='row' className='post-user-container'>
          <span
            className='ti-list post-menu'
            onClick={(e) => this.setState({ contextMenuIsOpen: true, contextMenuAnchorElement: e.target }) }
          />
          <ContextMenu
            closeMenu={() => this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null }) }
            isEditMode={this.state.isEditMode}
            editOnClick={() =>  this.setState(
            {
              contextMenuIsOpen: false,
              contextMenuAnchorElement: null,
              isEditMode: true,
              editText: this.props.post.text
            })}
            cancelEditOnClick={() => this.setState(
            {
              contextMenuIsOpen: false,
              contextMenuAnchorElement: null,
              isEditMode: false
            })}
            deleteOnClick={() =>
            {
              this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null });
              this.props.deletePost();
            }}
            reportOnClick={() =>
            {
              this.setState({ contextMenuIsOpen: false, contextMenuAnchorElement: null });
              this.props.reportPost();
            }}
            anchorElement={this.state.contextMenuAnchorElement}
            canEdit={this.props.user._id.toString() === this.props.post.createdBy._id.toString()}
            canReport={this.props.user._id.toString() !== this.props.post.createdBy._id.toString()}
            isOpen={this.state.contextMenuIsOpen}
          />
          <Avatar
            alt="Profile image"
            src={this.props.post.createdBy.photo ? this.props.post.createdBy.photo : require('../../asset/no-photo.png')}
            className={'post-avatar'}
            onClick={() =>
            {
              const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(this.props.post.createdBy._id.toString()) + '/';
              this.props.updateMasterState({ redirect: redirect })
            }}
          />
          <Grid className='post-name-date'>
            <Typography
              variant="h6"
              classes={{h6: 'post-name'}}
              display='block'
              align={'left'}
              onClick={() =>
              {
                const redirect = '/view/user/' + NotificationUtility.base64EncodeUnicode(this.props.post.createdBy._id.toString()) + '/';
                this.props.updateMasterState({ redirect: redirect })
              }}
            >
              {this.props.post.createdBy.firstName + ' ' + this.props.post.createdBy.lastName}
            </Typography>
            <Typography
              variant="subtitle2"
              classes={{subtitle2: 'post-date'}}
              align={'left'}
              display='block'
            >
              {Datetime.formatFullDate(this.props.post.createdOn)}
            </Typography>
          </Grid>
        </Grid>

        {this.props.post.files.length > 0 &&
        <Typography
          variant="body2"
        >
          {fileType === 'image' &&
          <img src={this.props.post.files[0]} alt='Post image' className='post-image' />}

          {fileType === 'video' &&
          <VideoPlayer
            className='post-image'
            src={this.props.post.files[0]}
            width={720}
            height={420}
            controls={true}
            id={`post-video-${this.props.post._id.toString()}`}
          />}

          {fileType === 'unknown' &&
          'Unsupported file type'}
        </Typography>}
        {!this.state.isEditMode &&
        <Typography
          variant="body2"
          paragraph={true}
          align='left'
        >{this.props.post.text}
        </Typography>}
        {this.state.isEditMode &&
        <Form.Control
          as="textarea"
          rows="6"
          onChange={ (e) => this.setState({ editText: e.target.value }) }
          value={this.state.editText}
        />}
        {this.state.isEditMode &&
        <Button
          onClick={() =>
          {
            this.props.updatePost(this.state.editText);
            this.setState({ isEditMode: false });
          }}
          className='post-save-btn'
        >{AppText.posts.post.comment.save}</Button>}
      </Paper>
      <Actions
        user={this.props.user}
        like={() => this.props.like(this.props.post._id)}
        comment={() => this.props.comment(this.props.post._id, this.state.comments.text)}
        toggleComments={() =>
        {
          const comments = {...this.state.comments};
          comments.isShowing = !comments.isShowing;
          this.setState({ comments: comments });
        }}
        commentsShowing={this.state.comments.isShowing}
        siteManager={this.props.siteManager}
        post={this.props.post}
      />
      {this.state.comments.isShowing &&
      <Comments
        user={this.props.user}
        comments={this.props.post.comments}
        updateComment={this.props.updateComment}
        deleteComment={this.props.deleteComment}
        newCommentText={this.state.comments.text}
        isValid={this.state.comments.isValid}
        updateMasterState={(id, val) =>
        {
          const comments = {...this.state.comments};
          comments[id] = val;
          this.setState({ comments: comments });
        }}
        isValid={this.state.comments.isValid}
        onChange={(e) =>
        {
          // Not valid
          if(e.target.value.length > this.props.component.properties.schemaFields.comment_message.maxLength)
          {
            const comments = {...this.state.comments};
            comments.isValid = false;
            this.setState({ comments: comments });
            return;
          }

          // Valid
          const comments = {...this.state.comments};
          comments.text = e.target.value;
          comments.isValid = true;
          this.setState({ comments: comments });
        }}
        onClick={async (e) =>
        {
          const result = await this.props.comment(this.props.post._id, this.state.comments.text);
          console.log(result);
          // Clear text on success
          if(result)
          {
            const comments = {...this.state.comments};
            comments.text = '';
            this.setState({ comments: comments });
          }
        }}
      />}
    </Grid>
    </this._css>);
  }

  // Style component
  styleComponent = (siteManager) =>
  {
  //  let btnColor = siteManager.getColorFor('Form', 'Submit Button');
    return StyledComponent.div`
      .post-container
      {
        margin: auto;
        padding-bottom: 10px;
        height: fit-content;
      }
      .post-user-container
      {
        border-bottom: 1px solid #d1d2d4;
        padding-bottom: 5px;
      }
      .comment-user-container
      {
        padding-bottom: 10px;
        padding-top: 10px;
      }
      .paper
      {
        padding: 20px;
      }
      .post-menu
      {
        font-size: 40px;
        margin: auto 10px auto 10px;
        text-align: center;
        display: block;
        color: #225373;

        &:hover
        {
          cursor: pointer !important;
        }
      }
      .post-image
      {
        max-width: 100%;
        max-height: 100%;
        margin: 0px 0px 10px 0px;
      }
      .post-name-date
      {
        margin-left: 10px;
      }
      .comment-name-date
      {
        @media only screen and (max-width: 1200px) and (min-width: 768px)
  			{
  				margin-left: 10px;
  			}
      }
      .comment-name-row
      {
        display: flex;
        flex-wrap: wrap;
      }
      .comment-actions
      {
        margin-left: auto;
      }
      .post-name
      {
        color: #225373;
        font-weight: 600;
        line-height: 1.1;
      }
      .comment-text
      {
        color: #000;
        font-weight: 600;
        line-height: 1.1;
      }
      .post-date
      {
        color: #9b9da0;
      }
      .post-avatar
      {
        height: 60px;
        width: 60px;
        margin-left: 20px;
        border: 3px solid #cecfd1;
      }
      .comment-avatar
      {
        height: 60px;
        width: 60px;
      }
      .btn-primary
      {
        background: ${Colors.posts.post.comments.sendButton.background.color};
        color: ${Colors.posts.post.comments.sendButton.text.color};
      }
      .comments-row
      {
        margin-bottom: 20px;
        padding-top:   20px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
      }
      .comment-edit-row
      {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
      }
      .comment-save-btn
      {
        float: right;
        margin-left: auto;
      }
      .post-save-btn
      {
        width: 100%;
        margin-top: 5px;
      }
      width: 100%;
      margin: auto;
    `;
  }
}

export default Post;
