import React from 'react';
import StyledComponent from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Colors from '../../constant/colors';
import AppText from '../../constant/text';

export default class Actions extends React.Component
{
  // MARK: - Data fields
  _css = null;


  // MARK: - Constructor
  constructor(props)
  {
    console.log("\tPostActions()");
    super(props);

    this.state = { };
    this._css = this.styleComponent(props.siteManager);
  }

  render()
  {
    let subText = '';
    //console.log(this.props);

    let likeBtnClass = 'like-btn-inactive';
    let likeBtnText = AppText.posts.post.actions.like;
    let likeBtnIconClass = 'action-icon-like-inactive';
    let isLiked = false;
    for(let i = 0; i < this.props.post.likedBy.length; i++)
    {
      if(this.props.post.likedBy[i].toString() === this.props.user._id.toString())
      {
        likeBtnClass = 'like-btn-active';
        likeBtnText = AppText.posts.post.actions.unlike;
        likeBtnIconClass = 'action-icon-like-active';
        isLiked = true;
        break;
      }
    }

    if(isLiked)
    {
      if(this.props.post.likedBy.length === 1)
      {
        subText = 'You like this';
      }
      else if(this.props.post.likedBy.length === 2)
      {
        subText = 'You and 1 other like this';
      }
      else
      {
        subText = 'You and ' + (this.props.post.likedBy.length - 1) + ' others like this';
      }
    }
    else
    {
      //console.log(this.props.post.likedBy.length);
      if(this.props.post.likedBy.length === 1)
      {
        subText = '1 other like this';
      }
      else if(this.props.post.likedBy.length > 1)
      {
        subText = this.props.post.likedBy.length + ' others like this';
      }
    }

    let commentText = (this.props.commentsShowing ? AppText.posts.post.actions.comments : AppText.posts.post.actions.comment);

    return (
      <this._css>
        <div className='btn-container like-btn-container'>
          <button
            className={likeBtnClass}
            onClick={this.props.like}
          >
            <span className={`ti-heart action-icon ${likeBtnIconClass}`} />
            <span className="btn-text">{likeBtnText}</span>
          </button>
          {isLiked &&
          <div className='row justify-content-center'>
            <span className='ti-thumb-up like-thumb' />
            <p className='sub-text'>{subText}</p>
          </div>}
        </div>

        <div className='btn-container comment-btn-container'>
          <button
            className={'comment-btn-inactive'}
            onClick={this.props.toggleComments}
          >
            <span className='ti-comment action-icon'/>
            <span className="btn-text">{`${commentText}  `}</span>
            {this.props.commentsShowing && <span className="ti-angle-down" />}
          </button>
        </div>
      </this._css>
    );
  }

  /*
  <div class="like-count" data-id="41">
    <span class="icon-icon_thumbs"></span>
    <span class="like-count-text" id="41">
      <span class="count" data-id="41"></span>
      other like this.
    </span>
  </div>
  */

  styleComponent = (siteManager) =>
  {
    if(siteManager !== null)
    {
      //linkText = siteManager.getColorFor('Side Navigation', 'Link Text');
    }

    return StyledComponent.div`

      .btn-container
      {
        width: 45%;
        text-align: center;

        @media only screen and (max-width: 1409px)
        {
          margin: auto;
          margin-bottom: 10px;
          width: 90%;
        }

        button
        {
          border-radius: 40px;
          height: 55px;
          line-height: 12px;
          text-align: center;
          position: relative;
          width: 100%;

          .action-icon
          {
            border-radius: 50%;
            padding: 6px;
            font-size: 28px;
            position: absolute;
            top: 5px;
            left: 5px;
            line-height: 28px;
            margin-right: 30px;
          }
          .action-icon-like-inactive
          {
            background-color: ${Colors.posts.post.actions.like.inactive.secondary.color};
            color: ${Colors.posts.post.actions.like.inactive.primary.color};
          }
          .action-icon-like-active
          {
            background-color: ${Colors.posts.post.actions.like.active.secondary.color};
            color: ${Colors.posts.post.actions.like.active.primary.color};
          }
          .btn-text
          {
            padding-left: 10px;
            font-weight: 400;
          }
        }
      }
      .like-btn-container
      {
        @media only screen and (min-width: 1410px)
        {
          float: left;
        }

        .like-thumb
        {
          color: ${Colors.posts.post.actions.like.active.primary.color};
        }
      }
      .comment-btn-container
      {
        @media only screen and (min-width: 1410px)
        {
          float: right;
        }
      }
      .like-btn-inactive
      {
        color: ${Colors.posts.post.actions.like.inactive.primary.color};
        background: ${Colors.posts.post.actions.like.inactive.secondary.color};
      }
      .like-btn-active
      {
        color: ${Colors.posts.post.actions.like.active.secondary.color};
        background: ${Colors.posts.post.actions.like.active.primary.color};
      }

      .comment-btn-inactive
      {
        color: ${Colors.posts.post.actions.like.inactive.secondary.color};
        background: ${Colors.posts.post.actions.like.inactive.primary.color};
      }

      .sub-text
      {
        font-weight: 400;
        margin-left: 5px;
        font-family: Open Sans;
      }
      flex-wrap: wrap;
      width: 100%;
      overflow: auto;
      padding: 10px;
      box-shadow: inset 0px 3px 20px 4px rgba(171, 171, 171, 0.2);
      background: ${Colors.posts.post.actions.background.color};
    `;
  }
}
