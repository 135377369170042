import React from 'react';

import { Nav, Navbar } from 'react-bootstrap';
import StyledComponent from 'styled-components';

import { Notifications } from '../../notifications';

const Layout1 = ({  updateMasterState,
                    history,
                    title,
                    navOnChange,
                    loggedIn,
                    cookies,
                    notificationManager,
                    siteManager,
                    forward,
                    isMobile,
                    component,
                    path }) =>
{
  return (
  <_css>
    <Navbar expand="lg" variant="dark">
      <Navbar.Brand
        onClick={(e) =>
        {
          updateMasterState({ selectedNavItem: -1 });
          history.push(loggedIn ? '/home' : '/');
        }}
      >{title}</Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="ml-auto"
          onSelect={e => navOnChange(e)}
        >
          {loggedIn &&
          <Nav.Item key={'navigation-bar-notifications-item'}>
            <Nav.Link key={'navigation-bar-notifications-link'}>
              <Notifications
                cookies={cookies}
                notificationManager={notificationManager}
                siteManager={siteManager}
                updateMasterState={updateMasterState}
                forward={forward}
              />
            </Nav.Link>
          </Nav.Item>}
          {isMobile &&
          component &&
          component.navData.map( (item, i) =>
          <Nav.Item key={`navigation-bar-${i}-item`}>
            <Nav.Link
              key={`navigation-bar-${i}-link`}
              eventKey={item.title}
            >
              {item.icon.indexOf('.png') === -1 ? <span className={item.icon}/>
                                                : <img className={'link-img'} src={require('../../../asset/' + item.icon)} />}
              {item.displayName}
            </Nav.Link>
          </Nav.Item>)}
          {loggedIn &&
          <Nav.Item key={`navigation-bar-reports-item`}>
            <Nav.Link
              key={`navigation-bar-reports-link`}
              eventKey={'reports'}
            >{'Reports'}
            </Nav.Link>
          </Nav.Item>}
          {loggedIn &&
          <Nav.Item key={`navigation-bar-jobs-item`}>
            <Nav.Link
              key={`navigation-bar-jobs-link`}
              eventKey={'jobs'}
            >{'Jobs'}
            </Nav.Link>
          </Nav.Item>}
          {loggedIn &&
          <Nav.Item key={`navigation-bar-emails-item`}>
            <Nav.Link
              key={`navigation-bar-emails-link`}
              eventKey={'emails'}
            >{'Email Templates'}
            </Nav.Link>
          </Nav.Item>}
          <Nav.Item
            key={'navigation-bar-logout-item'}
          >
            {loggedIn ? <Nav.Link
                          key={`navigation-bar-logout-link`}
                          eventKey={'logout'}
                        >Logout</Nav.Link>
                      : (path === 'login' ? '' : <Nav.Link
                                                  key={`navigation-bar-logout-link`}
                                                  href="/login">Login</Nav.Link>)}
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </_css>);
}

const _css = StyledComponent.div`
  .link-img
  {
    width: 30px;
    margin-right: 10px;
  }
  .name
  {
    text-align: center;
    color: #FFF;
    font-weight: 600;
  }
  .avatar
  {
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 5px;
    border: 3px solid #cecfd1;
  }
  .navbar
  {
    background-color: #222;
  }
  a, .navbar-brand, .navbar-nav .nav-link
  {
    color: #FFF !important;

    &:hover
    {
      color: white;
    }
  }
`;

export default Layout1;
