import React from 'react';

const NoMatch = ({ cookies, forward }) =>
{
  const token = cookies.get('token');
  if(!token)
  {
    forward('/login');
  }
  
  return (
    <div>
      <h2>No Match</h2>
    </div>
  );
}

export default NoMatch;
