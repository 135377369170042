import React, { Component } from 'react';
import {  Badge,
          Paper,
          Typography } from '@material-ui/core';
import StyledComponent from 'styled-components';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { Notification } from './notification';
import { AppText } from '../../constant';

const Layout1 = ({  containerRef,
                    notificationRef,
                    scrollRef,
                    unreadCount,
                    toggleShow,
                    styles,
                    showing,
                    notifications,
                    forward,
                    markRead  }) =>
{
  const cardList = Array.isArray(notifications) && notifications.map( (item) =>
  (
    <Notification
      key={item._id}
      item={item}
      markRead={(id) =>
      {
        this.markRead(id);
      }}
      onClick={(url) =>
      {
        this.props.forward(url);
      }}
    />
  ));

  return (
    <_css ref={containerRef}>
      <Badge
        badgeContent={unreadCount}
        classes={{badge: 'notification-badge'}}
        onClick={toggleShow}
      >
        <NotificationsIcon />
      </Badge>

      <Paper
        elevation={24}
        className={'container'}
        ref={notificationRef}
        style={
        {
          ...styles,
          visibility: showing ? 'visible' : 'hidden',
          opacity: showing ? 1 : 0
        }}
      >
        <div className={'header'}>
          <Typography
            variant="h6"
            classes={{h6: 'header-title'}}
            align={'left'}
            display='block'
          >{AppText.navigation.notifications.header.title}
          </Typography>
          <Typography
            variant="button"
            classes={{button: 'header-option'}}
            align={'right'}
            display='block'
            onClick={() =>
            {
              const ids = [];
              for(let i = 0; i < notifications.length; i++)
              {
                ids.push(notifications[i]._id);
              }
              markRead(ids);
            }}
          >{AppText.navigation.notifications.header.clearAll}
          </Typography>
        </div>

        <div
          className={'items'}
          ref={scrollRef}
        >
          {notifications.length > 0 ? (
            <>
              {cardList}
            </>
          ) : (
            <div className={'empty-notifications'}>
              <div>{AppText.navigation.notifications.body.none}</div>
            </div>
          )}
        </div>
      </Paper>
    </_css>
  );
}

const _css = StyledComponent.div`
  .notification-badge
  {
    background-color: #70a040;
  }
  .container
  {
    @media only screen and (min-width: 440px)
    {
      width: 430px;
    }
    position: absolute;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    cursor: default;
  }

  .header
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;

    .header-title
    {
      color: #225373;
    }

    .header-option
    {
      margin: 0 5px;
      color: #365899;
      float: right;

      &:hover
      {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .items
  {
    height: 400px;
    width: 100%;
    overflow-y: auto;
  }

  .empty-notifications
  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }

  .card
  {
    margin-bottom: 5px;
    display: flex;
    &:hover
    {
      background-color: #ebedf0;

      .options
      {
        visibility: visible;
      }
    }

    .content
    {
      display: flex;
      padding: 5px;

      .notification-body
      {
        margin: 0 8px;
      }

      .notification-avatar
      {
        height: 40px;
        width: 40px;
      }
    }

    .date-and-options
    {
      display: flex;
      justify-content: space-between;
      padding: 5px;

      .notification-date
      {
        color: #9b9da0;
      }
      .options
      {
        text-align: right;
        cursor: pointer;
        color: #999;

        &:hover
        {
          opacity: 1;
        }
      }
    }
  }
`;

export default Layout1
