import Config 	from 	'../config';
import { NotificationManager } from '../managers';

export default class WebsocketClient
{
  static #instance = null;
  #client = null;
  #pingTimeout = null;
  #idMsg = {};
  #connectionAttempt = 0;
  apiToken = null;

  // Singleton accessor
  /**
    @param  {String}  apiToken  API Token so we can identify ourselves
    @returns {WebsocketClient}
   */
  static async GetInstance(apiToken)
  {
    // Initialize
    if(WebsocketClient.#instance == null)
    {
      WebsocketClient.#instance = new WebsocketClient();
    }

    // Always save token passed in on each call
    WebsocketClient.#instance.apiToken = apiToken;

    // Establish connection
    if(WebsocketClient.#instance.#client === null)
    {
      console.log('\t\tClient.<Websocket> instantiated');
      WebsocketClient.#instance.connect();

      // We will send this on all messages so we can be identified
      WebsocketClient.#instance.#idMsg =
      {
        token: apiToken ? apiToken : ''
      };

      //console.log('\t\tClient.<Websocket> using token: ' + apiToken);
    }

    return WebsocketClient.#instance;
  }

  connect()
  {
    console.log('\t\tClient.<Websocket> creating new connection');
    WebsocketClient.#instance.#client = new WebSocket('wss' + Config.API_SERVER_URL.replace('https', ''));

    // Setup message handlers
    WebsocketClient.#instance.#client.onopen = WebsocketClient.#instance.onOpen;
    WebsocketClient.#instance.#client.onmessage = WebsocketClient.#instance.onMessage;
    WebsocketClient.#instance.#client.onerror = WebsocketClient.#instance.onError;
    WebsocketClient.#instance.#client.onclose = WebsocketClient.#instance.onClose;
  }

  // MARK: Token related
  validateToken(apiToken)
  {
    if(!apiToken)
    {
      console.log('\t\tClient.<Websocket> closing connection');
      WebsocketClient.#instance.#client.close();
    }
    if(apiToken !== this.apiToken)
    {
      console.log('\t\tClient.<Websocket> updating API token: ' + apiToken);
      WebsocketClient.#instance.apiToken = apiToken;
      const msg = WebsocketClient.#instance.#idMsg;
      msg.type = 'token';
      msg.token = apiToken;
      this.apiToken = apiToken;
      if(WebsocketClient.#instance.#client.readyState === WebsocketClient.#instance.#client.OPEN)
      {
        WebsocketClient.#instance.#client.send(JSON.stringify(msg));
      }
      else
      {
        console.log('\t\tClient.<Websocket> connection not open: ' + WebsocketClient.#instance.#client.OPEN);
        // Try to reconnect 3 times max
        if(this.#connectionAttempt < 3)
        {
          this.#connectionAttempt += 1;
          this.connect();
          this.validateToken(apiToken);
        }
        else
        {
          console.log('\t\tClient.<Websocket> max connection attempts reached. Goodbye.');
        }
      }
    }
  }


  // MARK: Message handlers
  onOpen = () =>
  {
    console.log('\t\tClient.<Websocket>onOpen()');

    // Tell the server who we are
    const msg = WebsocketClient.#instance.#idMsg;
    msg.type = 'token';
    console.log(msg.token);
    WebsocketClient.#instance.#client.send(JSON.stringify(msg));

    // Kick off heart beat ping loop
    WebsocketClient.#instance.#pingTimeout = setTimeout( () =>
    {
      console.log("\t\tClient.<Websocket>.pingTimeout()");
      WebsocketClient.#instance.#client.close();

      // Reconnect
      WebsocketClient.#instance.connect();
    }, 8000 + 5000);
  }

  onMessage = (message) =>
  {
    //console.log('\t\tClient.<Websocket>onMessage(' + message.data + ')');
    const msg = JSON.parse(message.data);

    if(msg.type === 'heartbeat')
    {
      this.heartbeat(JSON.parse(message.data));
    }
    else if(msg.type === 'notification')
    {
      NotificationManager.GetInstance().newNotification(msg.notification);
    }
    //console.log('\t\tClient.<Websocket> message: ' + message.data);
  }

  onError = (err) =>
  {
    console.log('\t\tClient.<Websocket>onError(' + JSON.stringify(err, ['message', 'arguments', 'type', 'name']) + ')');
  }

  onClose = (evt) =>
  {
    console.log('\t\tClient.<Websocket>onClose(' + JSON.stringify(evt) + ')');
    clearTimeout(WebsocketClient.#instance.#pingTimeout);
  }


  // MARK: - Heartbeat related
  // Keep connection alive
  heartbeat(message)
  {
    console.log('\t\tClient.<Websocket>heartbeat(' + message.id + ')');

    // Clear timeout
    clearTimeout(this.#pingTimeout);

    // Ping server
    const msg = WebsocketClient.#instance.#idMsg;
    msg.type = 'heartbeat';
    msg.id = message.id;
    this.#client.send(JSON.stringify(msg));

    this.#pingTimeout = setTimeout(() =>
    {
      console.log("\t\tClient.<Websocket>.pingTimeout()");
      this.#client.close();
    }, 8000 + 5000);
  }
}
