import { QueryDataCommand } from '../command/data';
import { NotificationUtility } from '../components/notifications';

const DEFAULT_APP = 'bg';

export default class DataManager
{
  static #instance = null;

  // { k: => 'pageName', v: => Object: {} }
  #dataStore = new Map();
  #showAlert = null;
  #cookies = null;
  #observers = [];

  /**
    Singleton accessor
    @param  {Function}  showAlert   So we can display alerts
    @returns {DataManager}
   */
  static GetInstance(showAlert)
  {
    // Initialize
    if(DataManager.#instance == null)
    {
      DataManager.#instance = new DataManager();
      DataManager.#instance.#showAlert = showAlert;

    }
    return DataManager.#instance;
  }



  // Retrieve information from backend
  /**
    @param  {String}  userToken   So we know if we are logged in or not
  */
  async initDataStore(cookies)
  {
    console.log('\t\tDataManager.init()');
    try
    {
      this.#cookies = cookies;
      let promises = [];
      let userId = cookies.get('user');
      if(userId)
      {
        userId = userId._id.toString();
      }
      if(userId)
      {
        // Vendors
        promises.push(this.execute(await new QueryDataCommand(
        {
          setIsLoading: (isLoading) => {},
          model: 'vendor',
          params:
          {
            isDeleted: false,
          },
          dataSetId: 'vendors',
          dataSetField: 'vendors'
        })));

        // Opportunities
        promises.push(this.execute(await new QueryDataCommand(
        {
          setIsLoading: (isLoading) => {},
          model: 'lead',
          params:
          {
            isDeleted: false,
            status:
            {
              $ne: 'disputed'
            }
          },
          dataSetId: 'leads',
          dataSetField: 'notDisputed'
        })));

        // Disputes
        promises.push(this.execute(await new QueryDataCommand(
        {
          setIsLoading: (isLoading) => {},
          model: 'lead',
          params:
          {
            isDeleted: false,
            status: 'disputed'
          },
          dataSetId: 'leads',
          dataSetField: 'disputed'
        })));
      }

      await Promise.all(promises);

      // Set app
      let app = cookies.get('app');
      if(!app)
      {
        app = DEFAULT_APP;
      }
      this.#dataStore.set('app', { active: app });

      return true;
    }
    catch(err)
    {
      console.log('DataManager.init error: ' + err + '\nError stack: ' + err.stack);
      return false;
    }
  }


  /**
    Execute command related to data processing
    @param  {Object}  command The command to call execute on
    @param  {Any} args  Remaning arguments to pass into command call
  */
  async execute(command, ...args)
  {
    return await command.execute(this, this.#showAlert, ...args);
  }

  // MARK: Getters and setters
  // TODO: Make these methods private and pass them into command execute so no one else can update data set without using commands
  /**
    Get data set
    @param  {String}  name  The name of the data set to retrieve
    @returns {Object} dataset
  */
  getData(name)
  {
    return {...this.#dataStore.get(name)};
  }

  /**
    Set data set
    @param  {String}  dataSetName   The name of the data set we will use in our map
    @param  {Any}  dataSet  The data to be saved
  */
  setData(dataSetName, dataSet)
  {
    this.#dataStore.set(dataSetName, dataSet);
  }

  setCookie(cookieField, cookieValue)
  {
    this.#cookies.set(cookieField, cookieValue, { path: '/' });
  }

  /**
    Search data set
    @param  {String}  set   The key in the datastore to retrieve
    @param  {String}  field   The field in the dataset to pull from
    @param  {String}  subfield  The actual field in that object to compare against
    @param  {String}  searchText  The search text
    @returns {Array.<Any>}  results
  */
  searchDataSet({ set, field, subfield, searchText })
  {
    let dataset = this.#dataStore.get(set);
    if(!dataset)
    {
      return [];
    }
    dataset = dataset[field];
    if(!dataset)
    {
      return [];
    }
    try
    {
      if(searchText === '')
      {
        return dataset;
      }
      dataset = dataset.filter(record => record[subfield].toString()
                                                          .toLowerCase()
                                                          .replace(/<[^>]*>?/gm, '')
                                                          .indexOf(searchText.toLowerCase()) !== -1);
      return dataset;
    }
    catch(err)
    {
      console.log(err);
      return [];
    }
  }

  async searchAllDataSets(searchText)
  {
    const finalResults = [];

    let userId = this.#cookies.get('user');
    if(userId)
    {
      userId = userId._id.toString();
    }

    let textToSearch = '';
    this.#dataStore.forEach((dict, key) =>
    {
      console.log(key);
      switch(key)
      {
        case 'vendors':
          for(let i = 0; i < dict.vendors.length; i++)
          {
            textToSearch = dict.vendors[i].name;
            if(textToSearch.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            {
              finalResults.push({
                type: 'Vendor',
                redirect: `/vendors?search=${NotificationUtility.base64EncodeUnicode('name:' + dict.vendors[i].name.toString())}`,
                displayText: dict.vendors[i].name
              });
            }
          }
          break;

        case 'leads':
          let name = '';
          let type = '';
          let vendorName = '';
          // Not disputed
          for(let i = 0; i < dict.notDisputed.length; i++)
          {
            name = dict.notDisputed[i].createdBy.firstName + ' ' + dict.notDisputed[i].createdBy.lastName;
            type = dict.notDisputed[i].type.label;
            textToSearch = name + type;
            if(textToSearch.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            {
              let queryString = 'createdBy.firstName:' + dict.notDisputed[i].createdBy.firstName;
              queryString += '|createdby.lastName:' + dict.notDisputed[i].createdBy.lastName;
              queryString += '|type.label:' + type;
              finalResults.push({
                type: 'Opportunity',
                redirect: `/opportunities?search=${NotificationUtility.base64EncodeUnicode(queryString)}`,
                displayText: name + ' - ' + type
              });
            }
          }
          // Disputed
          for(let i = 0; i < dict.disputed.length; i++)
          {
            name = dict.disputed[i].createdBy.firstName + ' ' + dict.disputed[i].createdBy.lastName;
            type = dict.disputed[i].type.label;
            vendorName = dict.disputed[i].vendor.name;
            textToSearch = name + type + ' ' + vendorName;
            if(textToSearch.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            {
              let queryString = 'createdBy.firstName:' + dict.disputed[i].createdBy.firstName;
              queryString += '|createdBy.lastName:' + dict.disputed[i].createdBy.lastName;
              queryString += '|type.label:' + type;
              queryString += '|vendor.name:' + vendorName;
              finalResults.push({
                type: 'Dispute',
                redirect: `/disputes?search=${NotificationUtility.base64EncodeUnicode(queryString)}`,
                displayText: name + ' - ' + vendorName
              });
            }
          }
          break;

        default:
          break;
      }
    });
    return finalResults;
  }
  /**
    Notify observers that data reloaded
  */
  notifyObservers(forDataSet)
  {
    console.log('\t\tDataManager.dataReloaded()');
    for(let i = 0; i < this.#observers.length; i++)
    {
      if(this.#observers[i].forDataSet === forDataSet)
      {
        this.#observers[i].cb();
      }
    }
  }

  /**
    Add new observer to list
  */
  addObserver(id, cb, forDataSet)
  {
    let found = false;
    for(let i = 0; i < this.#observers.length; i++)
    {
      if(this.#observers[i].id === id)
      {
        found = true;
        break;
      }
    }

    if(!found)
    {
      this.#observers.push({ id: id, cb: cb, forDataSet: forDataSet });
    }
  }

  removeObserver(id)
  {
    for(let i = 0; i < this.#observers.length; i++)
    {
      if(this.#observers[i].id === id)
      {
        this.#observers.splice(i, 1);
        break;
      }
    }
  }
}
