import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import React from 'react';

const CsvButton = ({ csvData, fileName }) =>
{
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';


  return (
        <Tooltip title="Export selected rows to CSV">
          <IconButton onClick={ async () =>
            {
              const workSheetData = XLSX.utils.json_to_sheet(csvData);
              const workBookData = { Sheets: { 'data': workSheetData }, SheetNames: ['data'] };
              const excelBuffer = XLSX.write(workBookData, { bookType: 'xlsx', type: 'array' });
              const data = new Blob([excelBuffer], {type: fileType});
              FileSaver.saveAs(data, fileName + fileExtension);
            }} aria-label="export">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      );
}


export default CsvButton;
