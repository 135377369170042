import ApiRequest  from '../../api/request.js';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import FormLoader    from '../../components/Form/FormLoader';
import { Colors } from '../../constant';
import { NotificationManager } from '../../managers';
import { Container as Post } from '../../components/post';
import { Profile } from '../../components/profile';
import Dialog from '@material-ui/core/Dialog';

/**
  Handles a view for a single document
*/
export default class View extends React.Component
{
	_isMounted = false;
  _sentinal = '/view/';
  _lastPath = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('View()');
		super(props);

    // Get model type and ID from URL
    const modelType = props.location.pathname.substr(this._sentinal.length, props.location.pathname.indexOf('/', this._sentinal.length) - this._sentinal.length);
    let id = props.location.pathname.substr(props.location.pathname.indexOf(modelType) + modelType.length + 1);
    id = id.substr(0, id.indexOf('/'));

    // Decode base 64 to string
    id = new Buffer(id, 'base64').toString('ascii');

    console.log(id);

    this._lastPath = props.location.pathname;

    //console.log(id);

		this.state =
		{
			isLoading: false,
      entity:
      {
        type: modelType,
        document: { _id: id }
      },
      component:
      {
        type: '',
        details: []
      },
      selectedNavItem: -1,
      redirect: false,
		};
	}

  /**
    If we are already on this page this will load in a new notification
  */
  displayNewNotification()
  {
    console.log("View.displayNewNotification()");
    // Get model type and ID from URL
    const modelType = this.props.location.pathname.substr(this._sentinal.length, this.props.location.pathname.indexOf('/', this._sentinal.length) - this._sentinal.length);
    let id = this.props.location.pathname.substr(this.props.location.pathname.indexOf(modelType) + modelType.length + 1);
    id = id.substr(0, id.indexOf('/'));

    // Decode base 64 to string
    id = new Buffer(id, 'base64').toString('ascii');

    this.setState(
    {
      entity:
      {
        type: modelType,
        document: { _id: id }
      }
    }, () => this.loadData());
  }

	async componentDidMount()
	{
		console.log('View.componentDidMount()');
		this._isMounted = true;
		this.loadData();
	}

	// MARK: - API
	loadData = async () =>
	{
		console.log('View.loadData()');
    console.log('View: ' + JSON.stringify(this.state.entity));
		let user = this.props.cookies.get('user');
		if(user)
		{
			this.props.updateMasterState({ isLoading: true });

			try
			{
        var response = null;

        // Read notification (if coming here from notification tap )
        if(this.state.entity.type === 'notification')
        {
          const params =
          {
            aggregate: '_attach_comments_',
            id: this.state.entity.document._id
          };
          console.log(this.state.entity.document._id);
          response = await ApiRequest.sendRequest("post", params, "notification/read", this.props.cookies.get('token'));
        }
        // Otherwise direct link and just query the entity
        else
        {
          // If viewing user the profile component will handle data retrieval
          if(this.state.entity.type === 'user')
          {
            this.props.updateMasterState({ isLoading: false });
            return;
          }
          const params =
          {
            model: this.state.entity.type,
            params: { _id: this.state.entity.document._id },
            aggregate: '_attach_comments_'
          };
          //console.log(params);
          response = await ApiRequest.sendRequest("post", params, "data/query", this.props.cookies.get('token'));
        }
				if(response.data.error !== null)
				{
					this.props.updateMasterState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
					return;
				}

        console.log(response.data);

        let entity = {...this.state.entity};
        // Need to adjust type too
        if(this.state.entity.type === 'notification')
        {
          entity.document = response.data.results.document;
          entity.type = response.data.results.type;
          // Tell notification manager we read this
          this.props.notificationManager.markRead(this.state.entity.document);
        }
        // Only need to adjust document
        else
        {
          entity.type = response.data.results.type;
          entity.document = response.data.results.document;
          console.log('entity2');
          console.log(entity);
        }

        const component = this.props.siteManager.getComponent(response.data.results.type + 's');
        if(component)
        {
          component.details = [entity.document];
          this.props.updateMasterState({ isLoading: false });
  				this.setState({
            entity: entity,
            component: component,
            redirect: entity.type === 'message' ? '/messages/' + entity.document.conversation : false // If it's a message send them to message page with conversation ID
  				});
        }
        else
        {
          this.props.updateMasterState({ isLoading: false });
          this.setState(
          {
            entity: entity,
            redirect: entity.type === 'message' ? '/messages/' + entity.document.conversation : false // If it's a message send them to message page
          });
        }
			}
			catch(err)
			{
				this.props.updateMasterState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
	}

  shouldComponentUpdate(nextProps, nextState)
  {
    return (
      this.state.isLoading !== nextState.isLoading ||
      this.state.entity.document._id.toString() !== nextState.entity.document._id.toString() ||
      this.state.selectedNavItem !== nextState.selectedNavItem ||
      this.state.redirect !== nextState.redirect
    );
  }

  componentDidUpdate()
  {
    if(this._lastPath !== null && this._lastPath !== this.props.location.pathname)
    {
      this._lastPath = this.props.location.pathname;
      this.displayNewNotification();
    }

    if(this.state.redirect)
    {
      console.log('View.clearing redirect');
      this.props.updateMasterState({ selectedNavItem: -1 });
      this.setState({ redirect: false });
    }
  }

	// MARK: - Render
	render()
	{
		console.log('View.render()');
		return (
			<div>
        {this.state.redirect &&
        <Redirect to={{ pathname: this.state.redirect }} />}

        {this.state.entity.type === 'post' &&
        <Post
          dynamicData={false}
          component={this.state.component}
          user={this.props.cookies.get('user')}
          siteManager={this.props.siteManager}
          updateMasterState={(state) =>
          {
            const keys = Object.keys(state);
            const newState = {};
            for(let i = 0; i < keys.length; i++)
            {
              newState[keys[i]] = state[keys[i]];
            }
            this.setState(newState);
          }}
          showAlert={this.props.showAlert}
        />}
        {this.state.entity.type === 'user' &&
        <Profile
          siteManager={this.props.siteManager}
          cookies={this.props.cookies}
          showAlert={this.props.showAlert}
          pushManager={this.props.pushManager}
          userId={this.state.entity.document._id}
        />}
		  </div>
		);
	}

}
