import ApiRequest from '../api/request';

export default class PushManager
{
  static #singleton = null;
  /** @note https://blog.mozilla.org/services/2016/04/04/using-vapid-with-webpush/ */
  #vapidPublicKey = '';
  #pushToken = false;
  #subscribableEvents = [];
  #apiToken = null;

  // Singleton accessor that will also register/update token if we have it
  /**
    @param  {Function}  onPushNotification  Callback once we have received a push notification
    @param  {String}  apiToken   So we cna interact with API
    @returns {PushManager}
   */
  static async GetInstance(apiToken = null)
  {
    // Initialize
    if(PushManager.singleton == null)
    {
      PushManager.singleton = new PushManager();

      // Get details from backend
      await PushManager.singleton.init(apiToken);
    }
    return PushManager.singleton;
  }

  hasPushToken()
  {
    return this.#pushToken ? true : false;
  }

  subscribableEvents()
  {
    return [...this.#subscribableEvents];
  }


  /**
    Request permission to send notifications to this user
    @returns  {Bool}  True on permission granted, false if not
  */
  async requestPermission()
  {
    if(Notification.permission === 'default')
    {
      const consent = await Notification.requestPermission();
      return (consent === 'granted');
    }
    return (Notification.permission === 'granted');
  }

  hasPermission()
  {
    return (Notification.permission === 'granted');
  }

  /**
    Subscribe to push notifications
    @returns  {String}  JSON formatted as a string containing all data for subscription
  */
  async subscribeToPush()
  {
    try
    {
      console.log('\t\tPushManager.subscribeToPush()');
      // Wait for service worker installation to be ready
      const serviceWorker = await navigator.serviceWorker.ready;

      const options =
      {
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(this.#vapidPublicKey)
      };

      // If we don't have a token try to get one
      if(!this.hasPushToken())
      {
        // Subscribe and return the subscription
        const token = await serviceWorker.pushManager.subscribe(options);
        return this.savePushToken(token);
      }
      // If token hasn't updated in 4 hours, update it
      else
      {
        // If the token is old update it
        const updatedOn = new Date(this.#pushToken.updatedOn).getTime();
        const today = new Date().getTime();
        const difference = Math.abs(updatedOn - today);
        const msInHour = (1000 * 3600);

        if((difference / msInHour) > 4)
        {
          // Subscribe and return the subscription
          const token = await serviceWorker.pushManager.subscribe(options);
          return this.savePushToken(token);
        }
        return false;
      }
      return true;
    }
    catch(err)
    {
      console.error(err);
      return false;
    }
  }

  urlBase64ToUint8Array(base64String)
  {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // MARK: - APIs
  /**
		Save push token in backend
		@param 	{JSON}	pushToken 	The push token to save containing
		@note Ex: {"endpoint":"https://fcm.googleapis.com/fcm/send/d-KgZ0be6oU:APA91bHa0g5k-csVX1hGX2szoafydSze7YNNgxU3tygL-L33pkq2vxGtcNRvLwgh0GH3iQtC5easZZeyaxswvPa3L2ZWvYgeZS4QGeg4KtWZHkE","expirationTime":null,"keys":{"p256dh":"BBYWBTAXqS8wQB5xp7Fts4U_zcllRVx6jAPPXMTto","auth":"PCXG"}}
	*/
	async savePushToken(pushToken)
	{
    console.log('\t\tPushManager.savePushToken()');
		try
		{
			let params = { token: pushToken };
			let response = await ApiRequest.sendRequest("post", params, "push/token", this.#apiToken);
			if(response.data.error !== null)
			{
				return false;
			}
      this.#pushToken = response.data.results;
			console.log(response.data.results);
      return true;
		}
		catch(err)
		{
		  return false;
		}
	}

  // Retrieve information from backend
  async init(apiToken)
  {
    console.log('\t\tPushManager.init()');
    if(!apiToken)
    {
      return;
    }
    try
    {
      this.#apiToken = apiToken
      var response = await ApiRequest.sendRequest("post", {}, "push/init", this.#apiToken);
      if(response.data.error !== null)
      {
        console.error('PushManager.init error: ' + response.data.error);
        return false;
      }
      this.#vapidPublicKey = response.data.vapidPublicKey;
      this.#pushToken = response.data.pushToken;
      this.#subscribableEvents = response.data.subscribableEvents;

      // Update push token if
      if(this.hasPermission())
      {
        this.subscribeToPush();
      }
      return true;
    }
    catch(err)
    {
      console.log('PushManager.init error: ' + err + '\nError stack: ' + err.stack);
      return false;
    }
  }
}
