import React from 'react';
import StyledComponent from 'styled-components';


const Layout1 = ({ copyrightText }) =>
{
  return (
    <_css>
      <footer className='footer mt-auto py-3'>
        <div className='container'>{copyrightText}</div>
      </footer>
    </_css>
  );
}


const _css = StyledComponent.div`
  .footer
  {
    background-color: #222;
    color: #FFF !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
  }
`;

export default Layout1;
