import React from 'react';
import StyledComponent from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default class Header extends React.Component
{
  _css = null;

  // MARK; - Constructor
  constructor(props)
  {
    console.log("\tPostHeader()");
    super(props);

    this._css = this.styleComponent(props.siteManager);
  }

  // logo, title, description, siteManager, postOnClick
  shouldComponentUpdate(nextProps, nextState)
  {
    return false;
  }

  render()
  {
    return (
      <this._css>
        <img className="logo" src={require("../../asset/" + this.props.logo)} alt="" />
        <Typography
          variant="h3"
          classes={{h3: 'title'}}
          display='block'
          align={'center'}
        >{this.props.title}</Typography>
        <Typography
          variant="h6"
          display='block'
          align={'center'}
        >{this.props.description}</Typography>
        <div className="post-new-container">
          <button onClick={this.props.postOnClick} className="post-button">Post New Update | <span className="ti-pencil-alt2"></span><span className="ti-camera"></span></button>
        </div>
    	</this._css>
    );
  }

  // Style component
  styleComponent = (siteManager) =>
  {
    var bgColor = '#222';
    var textColor = '#FFF';
    if(this.props.siteManager !== null && this.props.siteManager !== undefined)
    {
      //console.log(props.siteManager);
      bgColor = this.props.siteManager.getColorFor('Header', 'Background');
      textColor = this.props.siteManager.getColorFor('Header', 'Text');
    }
    return StyledComponent.div`
      .post-button
      {
        display: block;
        margin: 0 auto;
        background: ${bgColor};
        color: ${textColor};
        border-radius: 20px;
        border: 0;
        padding: 6px 10px 8px 10px;
      }
      .post-new-container
      {
        padding-bottom: 40px;
        padding-top: 40px;
        margin-bottom: 40px;
      }

      .title
      {
        color: ${bgColor};
      }

      width: 100%;
      display: inline-block;
    `;
  }
}
