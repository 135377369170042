import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import StyledComponent from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TransitionModal from '../../components/TransitionModal';
import { CBadge } from '@coreui/react';
import ApiRequest    from '../../api/request.js';
import { Header } from './header';
import { Filter } from './filter';
import { Toolbar } from './toolbar';
import { Form, Form2 } from '../../components/Form';

/**
  @param  {Bool}  isPrintAvailable  If print button displays
  @param  {Bool}  isCsvAvailable    If CSV export button displays
*/
class EnhancedTable extends React.Component
{
  // MARK: - Data fields
  // Forms displayed by row exits
  _updateForm = null;
  _createForm = null;
  _viewForm = null;
  // Flag to control if form inputs changed and we need to setup state again
  _updateFormInputsChanged = false;
  _createFormInputsChanged = false;
  // Once we update form inputs after they were modified manually by parent
  // we need to be able to put them in our state and flag to
  // shouldComponentUpdate that there is no need to recheck
  _skipCheckingCreateFormInputs = false;

  // Styled component using CSS from backend
  _css = null;


  // MARK; - Constructor
  constructor(props)
  {
    console.log("Table()");
    super(props);

    // Add submit button to update form
    var updateFormInputs = [...props.updateFormInputs];
    if(updateFormInputs)
    {
      updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
    }

    // Add submit button to create form
    var createFormInputs = [...props.createFormInputs];
    if(createFormInputs)
    {
      createFormInputs.push({
        label: '',
        id: 'submit',
        element: 'input',
        type: 'submit',
        value: props.createForm.submitBtnText ? props.createForm.submitBtnText : 'Create Record',
        onClick: this.createFormOnSubmit,
        class: 'btn-block  .border .border--white',
        disabled: true,
        requireValidForm: true,
      });
    }

    // Handle initial search params
    const filters = new Map();
    if(this.props.initialSearch && this.props.initialSearch.length > 0)
    {
      for(let i = 0; i < this.props.initialSearch.length; i++)
      {
        filters.set(this.props.initialSearch[i].id, this.props.initialSearch[i].value.toLowerCase());
      }
    }

    // Need to modify update form inputs so copy to state
    // But first add on submit button to form
    this.state =
    {
      order: 'desc',
      orderBy: props.defaultSort,
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 25,

      updateModalIsVisible: false,
      updateFormInputs: updateFormInputs,

      createModalIsVisible: false,
      createFormInputs: createFormInputs,

      viewModalIsVisible: false,
      viewFormInputs: this.props.viewFormInputs,

      filters: filters,
    };

    this._updateForm = React.createRef();
    this._createForm = React.createRef();

    // Style component
    this._css = this.styleComponent(props.siteManager);
  }

  // MARK: Accessors
  getStateFor = (id) =>
  {
    return this.state[id];
  }

  getSelected = () =>
  {
    return this.state.selected;
  }

  clearSelected = () =>
  {
    this.setState({ selected: [] });
  }

  clearAll = () =>
  {
    // TODO: Clear filters as well
    this.setState({ selected: [], page: 0 });
  }


  // MARK: - Helpers
  formatDate = (date) =>
  {
    let dt = new Date(date);
    return(`${
    (dt.getMonth()+1).toString().padStart(2, '0')}/${
    dt.getDate().toString().padStart(2, '0')}/${
    dt.getFullYear().toString().padStart(4, '0')} ${
    dt.getHours().toString().padStart(2, '0')}:${
    dt.getMinutes().toString().padStart(2, '0')}:${
    dt.getSeconds().toString().padStart(2, '0')}`);
  }


  // MARK: - Sorting
  handleRequestSort = (event, property) =>
  {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({ order: (isDesc ? 'asc' : 'desc'), orderBy: property });
  };

  stableSort = (array, cmp) =>
  {
    if(array !== null)
    {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) =>
      {
        const order = cmp(a[0], b[0]);
        if (order !== 0)
        {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    else
    {
      return false;
    }
  }

  compare = (lhs, rhs, orderBy) =>
  {
    let rhsValue = this.extractValueFromPointer(orderBy, rhs);
    let lhsValue = this.extractValueFromPointer(orderBy, lhs);
    if (rhsValue < lhsValue)
    {
      return -1;
    }
    if (rhsValue > lhsValue)
    {
      return 1;
    }
    return 0;
  }

  getSorting = (order, orderBy) =>
  {
    if(order === null)
    {
      return false;
    }
    return order === 'desc' ? (a, b) => this.compare(a, b, orderBy) : (a, b) => -this.compare(a, b, orderBy);
  }

  applyFiltersAndSort = () => {
    return this.stableSort(this.props.data, this.getSorting(this.state.order, this.state.orderBy))
      .filter( (row) => // Apply filter
      {
        var isHiddenByFilter = false;
        var rowValue = null;

        let itr = this.state.filters[Symbol.iterator]();
        var filter = itr.next();
        while(filter.value)
        {
          rowValue = this.extractValueFromPointer(filter.value[0], row);

          // Find header for filter so we can check if isObject
          let header = null;
          for(let i = 0; i < this.props.headers.length; i++)
          {
            if(filter.value[0] === this.props.headers[i].id)
            {
              header = this.props.headers[i];
              break;
            }
          }

          let isInvalidNumber = false;
          try
          {
            isInvalidNumber = isNaN(filter.value[1].replace('<', '').replace('>', ''));
          }
          catch(err)
          {
            isInvalidNumber = false;
          }

          // Validate text
          if(isInvalidNumber || header.isObject)
          {

            // Date/time
            if(filter.value[0] === 'time' || filter.value[0] === 'createdOn' || filter.value[0] === 'pClass.time')
            {
              if(this.formatDate(rowValue).toLowerCase().indexOf(filter.value[1]) === -1)
              {
                isHiddenByFilter = true;
                break;
              }
            }
            // Not an object
            else if(!rowValue || (!header.isObject && rowValue.toLowerCase().indexOf(filter.value[1]) === -1))
            {
              isHiddenByFilter = true;
              break;
            }
            // Object
            else if(!rowValue || (header.isObject && JSON.stringify(rowValue).toLowerCase().indexOf(filter.value[1]) === -1))
            {
              isHiddenByFilter = true;
              break;
            }
          } // Validate number
          else
          {
            if(filter.value[1].indexOf('>') !== -1)
            {
              if(filter.value[1].replace('>', '') > rowValue)
              {
                isHiddenByFilter = true;
                break;
              }
            }
            else if(filter.value[1].indexOf('<') !== -1)
            {
              if(filter.value[1].replace('<', '') < rowValue)
              {
                isHiddenByFilter = true;
                break;
              }
            }
            else
            {
              if(rowValue != filter.value[1])
              {
                isHiddenByFilter = true;
                break;
              }
            }
          }

          filter = itr.next();
        }
        return !isHiddenByFilter;
      });
  };

  handleSelectAllClick = event =>
  {
    if (event.target.checked)
    {
      const newSelecteds = this.applyFiltersAndSort().map(row => row._id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  tableRowOnClick = (event, id) =>
  {
    // Select not enabled
    if(this.props.selectEnabled === false)
    {
      return;
    }

    if(this.props.tableRowOnClick)
    {
      this.props.tableRowOnClick(id);
    }
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if(this.props.multiSelectEnabled)
    {
      if (selectedIndex === -1)
      {
        newSelected = newSelected.concat(this.state.selected, id);
      }
      else if (selectedIndex === 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      }
      else if (selectedIndex === this.state.selected.length - 1)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      }
      else if (selectedIndex > 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex),
                                        this.state.selected.slice(selectedIndex + 1));
      }
    }
    else
    {
      newSelected = (selectedIndex === -1) ? [id] : [];
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) =>
  {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) =>
  {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  isSelected = (id) =>
  {
    var isSelected = (this.state.selected.indexOf(id) !== -1);
    return (this.state.selected.indexOf(id) !== -1);
  }

  extractValueFromPointer = (iFieldName, iRow) =>
  {
    //console.log(iFieldName);
    var fieldName = iFieldName;
    var fieldNameInPtr = "";
    var row = iRow;

    // Get total pointers in key
    let occurrences = (fieldName.match(/\./g) || []).length;
    if(occurrences === 0)
    {
      if(iRow[iFieldName] === undefined)
      {
        return '';
      }
      else
      {
        if(typeof iRow[iFieldName] === 'boolean')
        {
          return iRow[iFieldName].toString();
        }
        return iRow[iFieldName];
      }
    }

    // Iterate all pointers
    var splitIndex = -1;
    for(var i = 0; i < occurrences; i++)
    {
      splitIndex = fieldName.indexOf('.');
      try
      {
        // Extract pointer
        fieldNameInPtr  = fieldName.substring(splitIndex + 1);
        fieldName = fieldName.substring(0, splitIndex);

        // Slowly parse down the data
        if(fieldNameInPtr.indexOf('.') !== -1)
        {
          row = row[fieldName];
        }
        else
        {
          return row[fieldName][fieldNameInPtr].toString();
        }
        fieldName = fieldNameInPtr;
      }
      catch(err)
      {
        console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
        return "";
      }
    }
  }

  updateRecordForField = (iFieldName, iRow) =>
  {
    console.log('Table.updateRecordForField');
    console.log(iRow);
    var visibleText = "";
    var fieldName = iFieldName;
    var fieldNameInPtr = "";
    var row = iRow;

    // Get total pointers in key
    let occurrences = (fieldName.match(/\./g) || []).length
    if(occurrences === 0)
    {
      /*if(fieldName === 'location')
      {
        const location = JSON.parse(this.state[fieldName]);
        row[fieldName] =
        {
          "coordinates": [
            location[0],
            location[1]
          ],
          "type": "Point"
        };
      }
      else
      {*/
        row[fieldName] = this.state[fieldName];
      //}
      return row;
    }

    // Iterate all pointers
    var splitIndex = -1;
    for(var i = 0; i < occurrences; i++)
    {
      splitIndex = fieldName.indexOf('.');
      try
      {
        // Extract pointer
        fieldNameInPtr  = fieldName.substring(splitIndex + 1);
        fieldName = fieldName.substring(0, splitIndex);

        // Slowly parse down the data
        if(fieldNameInPtr.indexOf('.') !== -1)
        {
          row = row[fieldName];
        }
        else
        {
          // Format location as coordinates
          if(iFieldName.substr(iFieldName.lastIndexOf('.') + 1) === 'location')
          {
            const location = JSON.parse(this.state[iFieldName.substr(iFieldName.lastIndexOf('.') + 1)]);
            row[fieldName][fieldNameInPtr] =
            {
              "coordinates": [
                location[0],
                location[1]
              ],
              "type": "Point"
            };
          }
          else
          {
            row[fieldName][fieldNameInPtr] = this.state[iFieldName.substr(iFieldName.lastIndexOf('.') + 1)];
          }
          return row;
        }
        fieldName = fieldNameInPtr;
      }
      catch(err)
      {
        //console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
        return row;
      }
    }
  }

  /**
state.selected only contains unique ID's for selected rows and not the actual indices.
This is a helper function to convert selected ID's to actual table data at the rows of the unique ID's.

  @param  {JSON}  param     Parameters
       -  {Bool}  isForCsv  If data is for csv
*/
getTableDataForSelectedRows = (params) =>
{
  try
  {
    var allFormattedData = [];
    var selectedRecord = null;
    var headerId = "";
    var ptrData = "";

    // Iterate all selected IDs
    for(var i = 0; i < this.state.selected.length; i++)
    {
      var formattedData = {};

      // Find record for this ID
      selectedRecord = this.props.data.find(record => record._id === this.state.selected[i]);

      // If formatting for CSV export we need to pad data
      if(params && params.isForCsv)
      {
        // Iterate table headers
        for(var j = 0; j < this.props.headers.length; j++)
        {
          headerId = this.props.headers[j].id;
          ptrData = this.extractValueFromPointer(headerId, selectedRecord);
          if(typeof ptrData === 'object' && ptrData !== null)
          {
            // Build formatted string
            let formattedString = '';
            for(let k = 0; k < ptrData.length; k++)
            {
              let ptrDataKeys = Object.keys(ptrData[k]);
              for(let l = 0; l < ptrDataKeys.length; l++)
              {
                formattedString += ptrData[k][ptrDataKeys[l]];
                if(l != ptrDataKeys.length - 1)
                {
                  formattedString += ':';
                }
              }
              if(k != ptrData.length - 1)
              {
                formattedString += ',';
              }
            }
            //ptrData = JSON.stringify(ptrData, undefined, 2);
            ptrData = formattedString;
          }
          if(ptrData !== null && ptrData !== undefined)
          {
            //console.log('Header ID: ' + headerId);
            formattedData[this.props.headers[j].label] = ptrData.toString().replace(/<br\/>/gi, ' | ');
          }
          else
          {
            formattedData[this.props.headers[j].label] = '';
          }
        }
      } // Otherwise just send JSON object
      else
      {
        formattedData = selectedRecord;
      }
      // Add to list
      allFormattedData.push(formattedData);
    }

    return allFormattedData;
  }
  catch(err)
  {
    this.props.showAlert(true, 'Un-oh', err.toString(), 'danger');
    return [];
  }
}

  tableDidFinishLoading = (action, message, error) =>
  {
    this.props.tableDidFinishLoading(action, message, error);
  }

  /**
    When button action finishes we notify delegate and then delegate invokes this method
    passing in table data and this will return the data

    Didn't want to make the tableData part of the state here
    and didn't want to make the parent responsible for button logic
  */
  handleAction = (action, tableData) =>
  {
    switch(action.type)
    {
      case 'create':
        if(this.props.createForm.handleAction)
        {
          this.props.createForm.handleAction(action, tableData);
          return [...tableData];
        }
        else
        {
          var inserted = false;
          // Add to table
          var modifiedData = [...tableData];

          // No data
          if(modifiedData === null)
          {
            modifiedData = [action.data];
            inserted = true;
          }
          else if(modifiedData.length === 0)
          {
            modifiedData.push(action.data);
            inserted = true;
          }

          // Find position
          var lhs = -1;
          var rhs = -1;
          var found = false;
          var i = 0;
          while(!found && i < modifiedData.length && !inserted)
          {
            lhs = this.compare(modifiedData[i], action.data, this.state.orderBy);

            if(lhs <= 0) // New first element
            {
              modifiedData.splice(i, 0, action.data);
              found = true;
            }
            else
            {
              i++;
            }
          }
          if(!found && !inserted)
          {
            modifiedData.push(action.data);
          }
          return modifiedData;
        }
      case 'delete':

        // Get selected ID's
        var selected = this.getSelected();
        this.clearSelected();

        // Remove from table
        var modifiedData = [...tableData];
        let length = Array.isArray(selected) ? selected.length : 1;
        var selectedRowIdx = -1;
        for(var i = 0; i < length; i++)
        {
          selectedRowIdx = modifiedData.map( (data) => { return data['_id']; }).indexOf((Array.isArray(selected) ? selected[i] : selected));
          if(selectedRowIdx !== -1)
          {
            modifiedData.splice(selectedRowIdx, 1);
          }
          else
          {
          }
        }
        return modifiedData;

      case 'update':
        // Update table data with modified record
        var modifiedData = [...tableData];
        var modifiedIndex = modifiedData.map( (record) => { return record['_id']; }).indexOf(action.data._id);
        var updatedRecord = modifiedData[modifiedIndex];

        // Iterate update form and update our table with new data
        for(var i = 0; i < this.state.updateFormInputs.length - 1; i++)
        {
          if(this.state.updateFormInputs[i].managedUpdateForm)
          {
            updatedRecord = this.updateRecordForField(this.state.updateFormInputs[i].key ? this.state.updateFormInputs[i].key : this.state.updateFormInputs[i].id,
                                                      action.data);
          }
        }
        modifiedData[modifiedIndex] = updatedRecord;
        return modifiedData;

      default:
        return tableData;
      }
  }

  // MARK: - Edit button related
  // Close modal
  updateModalOnClose = () =>
  {
    this.setState({ updateModalIsVisible: false });
  }

  // Show modal
  updateButtonOnClick = () =>
  {
    console.log("Table.updateButtonOnClick()");
		var selectedRecord = this.props.data.find(record => record._id === this.state.selected[0]);
		var updateFormInputs = this.state.updateFormInputs;
    var key = "";

    // Iterate inputs (skipping submit button)
    for(var i = 0; i < updateFormInputs.length - 1; i++)
    {
      // Allow caller to overwrite the key if needed
      // Useful for SELECT element where ID is text we want to display
      // and key is where the value is located
      key = updateFormInputs[i].key ? updateFormInputs[i].key : updateFormInputs[i].id;

      // If reference field we want to give them the pointer value and let the FormValiateds
      // option list handle displaying the proper text based off keyInReference
      if(updateFormInputs[i].type === 'select' && updateFormInputs[i].reference)
      {
        //console.log(updateFormInputs[i]);
        // TODO: Figure out why this is causing issues
        //key += ('._id');
        key = updateFormInputs[i].id + '._id';
      }
      updateFormInputs[i].value = this.extractValueFromPointer(key, selectedRecord);
      //console.log(updateFormInputs[i]);
      //console.log(selectedRecord);

      // If point, try to find radius value
      if(updateFormInputs[i].type === 'point')
      {
        let radius = updateFormInputs.filter(input => input.id === 'radius');
        if(radius.length > 0)
        {
          radius = this.extractValueFromPointer('radius', selectedRecord);;
          updateFormInputs[i].radius = radius;
        }
      }
    }
    this.setState({ updateModalIsVisible: true, updateFormInputs: updateFormInputs });
  }

  // The actual form
  updateForm()
  {
    if(this.state.updateModalIsVisible)
    {
      return(
  		<div align="left" className="left-aligned">
        <Form
  				ref={this._updateForm}
  				formInputs={this.state.updateFormInputs}
  				formOnChange={this.updateFormOnChange}
  				showErrorList={false}
  				validateOnInit={true}
          siteManager={this.props.siteManager}
  			/>
  		</div>);
    }
    //console.log('Table.updateForm not showing');
  }

  // Handle modify form on change
  updateFormOnChange = (change, isFormValid) =>
	{

    let value = change.value;
    if(change.type === 'point')
    {
      value = ('[' + change.value.coordinates[0] + ', ' + change.value.coordinates[1] + ']');
    }
    // If radius, find point and update it's radius
    else if(change.id === 'radius')
    {
      // Find point type formInput and update it's radius
      for(let i = 0; i < this.state.updateFormInputs.length; i++)
      {
        if(this.state.updateFormInputs[i].type === 'point')
        {
          const updateFormInputs = [...this.state.updateFormInputs];

          if(updateFormInputs[i].value.coordinates)
          {
            updateFormInputs[i].value.radius = value;
          }
          else
          {
            const location = JSON.parse(updateFormInputs[i].value);
            updateFormInputs[i].value =
            {
              "coordinates": [
                location[0],
                location[1]
              ],
              "type": "Point",
              radius: value,
            };
          }

          this.setState({ updateFormInputs: updateFormInputs });
          if(this._updateForm.current)
          {
            this._updateForm.current.updateFormInputValue(updateFormInputs[i].id,
                                                          updateFormInputs[i].value,
                                                          updateFormInputs[i].validation,
                                                          updateFormInputs[i].type);
          }
          break;
        }
      }
    }
		this.setState({ [change.id.substr(change.id.lastIndexOf('.') + 1)]: value });
	}

  // Send updated form to backend
  updateFormOnSubmit = async(evt) =>
  {
    evt.preventDefault();

    this.props.tableDidStartLoading();

    //console.log(this.state);
    // Build form data
    const formData = new FormData();
    var fieldId = null;
    for(var i = 0; i < this.state.updateFormInputs.length; i++)
    {
      // Don't process submit button
      if(this.state.updateFormInputs[i].type !== 'submit')
      {
        fieldId = this.state.updateFormInputs[i].id.substr(this.state.updateFormInputs[i].id.lastIndexOf('.') + 1);
        if(this.state.updateFormInputs[i].type === 'file')
        {
          formData.append(fieldId, this.state[fieldId]);
        }
        else if(this.state.updateFormInputs[i].type === 'point')
        {
          let locationValue = this.state[fieldId];
          if(locationValue.coordinates)
          {
            locationValue = ('[' + locationValue.coordinates[0] + ', ' + locationValue.coordinates[1] + ']');
          }
          formData.set(fieldId, locationValue);
        }
        else if(this.state.updateFormInputs[i].type === 'location')
        {
          let locationValue = this.state[fieldId];
          locationValue = JSON.stringify(locationValue);
          formData.set(fieldId, locationValue);
        }
        else
        {
          // Skip buttons
          formData.set(fieldId, this.state[fieldId]);
        }
      }
    }

    // Send over ID of selected row
    formData.set('id',        this.state.selected[0]);
    formData.set('model',     this.props.model);

    try
    {
      var action =
      {
          type: 'update',
          data: null
      };

      let response = await ApiRequest.sendRequest("post", formData, "data/update", this.props.cookies.get('token'), 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
      // Success
      if(response.data.error === null)
      {
        action.data = response.data.results;

        // Notify parent
        this.props.tableDidFinishLoading(action, response.data.message, null);

        this.setState({ updateModalIsVisible: false });
        // TODO: I think because of the conditional rendering and hiding of the form we no longer
        // need this check/call
        if(this._updateForm.current !== null)
        {
          this._updateForm.current.reset();
        }
      }
      // Failed
      else
      {
        this.props.tableDidFinishLoading(action, null, response.data.error);
      }
    }
    catch(err)
    {
      this.props.tableDidFinishLoading(action, null, 'An error has occurred, please try again or contact support.\nError: ' + err);
    }
  }



  // MARK: - Create button related
  // Close modal
  createModalOnClose = () =>
  {
    this.setState({ createModalIsVisible: false });
  }

  // Show modal
  createButtonOnClick = () =>
  {
    this.setState({ createModalIsVisible: true });
  }

  // The actual form
  createForm()
  {
    if(this.state.createModalIsVisible)
    {
      // TODO: Layout2 is tricky to get working with validation, will circle back if necessary
      return(
  		<div align="left" className="left-aligned">
  			<Form
  				ref={this._createForm}
  				formInputs={this.state.createFormInputs}
          title={this.props.createForm.title}
          description={this.props.createForm.description}
  				formOnChange={this.createFormOnChange}
  				showErrorList={false}
  				validateOnInit={true}
          siteManager={this.props.siteManager}
  			/>
  		</div>);
    }
    //console.log('Table.createForm not showing');
  }

  // Handle create form on change
  createFormOnChange = (change, isFormValid) =>
	{
    console.log(change);
    let value = change.value;
    if(change.type === 'point')
    {
      value = ('[' + change.value.coordinates[0] + ', ' + change.value.coordinates[1] + ']');
    }
    // No ID passed in if an error such as not authorized to query a reference model type
    let formattedId = change.id ? change.id.substr(change.id.lastIndexOf('.') + 1) : '';
		this.setState({ [formattedId]: value });
	}

  // Send updated form to backend
  createFormOnSubmit = async(evt) =>
  {
    evt.preventDefault();

    console.log('Table.createFormOnSubmit()');
    this.props.tableDidStartLoading();

    // Build form data
    const formData = new FormData();
    const jsonBody = {};
    var fieldId = null;
    for(var i = 0; i < this.state.createFormInputs.length; i++)
    {
      fieldId = this.state.createFormInputs[i].id.substr(this.state.createFormInputs[i].id.lastIndexOf('.') + 1);
      console.log(fieldId);
      console.log(this.state.createFormInputs[i].id);
      if(this.state.createFormInputs[i].type === 'file')
      {
        formData.append(fieldId, this.state[fieldId]);
      }
      else
      {
        // Skip buttons
        if(this.state.createFormInputs[i].type !== 'submit')
        {
          // Array
          if(this.state.createFormInputs[i].multiSelectEnabled)
          {
            let value = (this.state[fieldId] === undefined ? '_empty_array_' : this.state[fieldId]);
            formData.set(fieldId, value);
          } // Single value
          else
          {
            if(this.props.createForm && this.props.createForm.useJsonBody) {
              jsonBody[fieldId] = this.state[fieldId];
            } else {
              formData.set(fieldId, this.state[fieldId]);
            }
          }
        }
      }
    }
    // Send over ID of selected row
    formData.set('model', this.props.model);
    try
    {
      var action =
      {
        type: 'create',
        data: null
      };
      console.log(this.state);
      let response = await ApiRequest.sendRequest("post",
        this.props.createForm && this.props.createForm.useJsonBody ? jsonBody : formData,
        this.props.createForm.route ? this.props.createForm.route : "data/create",
        this.props.cookies.get('token'));
      console.log(response.data);
      // Success
      if(response.data.error === null)
      {
        action.data = response.data.results;

        // Notify parent
        this.props.tableDidFinishLoading(action, response.data.message, null);

        // Hide and reset form
        this.setState({ createModalIsVisible: false });

        // TODO: I think because of the conditional rendering and hiding of the form we no longer
        // need this check/call
        if(this._createForm.current !== null)
        {
          this._createForm.current.reset();
        }
      }
      // Failed
      else
      {
        this.props.tableDidFinishLoading(action, null, response.data.error);
      }
    }
    catch(err)
    {
      this.props.tableDidFinishLoading(action, null, 'An error has occurred, please try again or contact support.\nError: ' + err);
    }
  }

  // MARK: - View button related
  // Close modal
  viewModalOnClose = () =>
  {
    this.setState({ viewModalIsVisible: false });
  }

  // Show modal
  viewButtonOnClick = () =>
  {
    this.setState({ viewModalIsVisible: true });
  }

  // The actual form
  viewForm()
  {
    if(this.state.viewModalIsVisible)
    {
      return(
  		<div align="left" className="left-aligned">
  			<Form
  				ref={this._viewForm}
  				formInputs={this.state.viewFormInputs}
          title={this.props.viewForm.title}
          description={this.props.viewForm.description}
  				formOnChange={() => console.warn('Not implemented')}
  				showErrorList={false}
  				validateOnInit={false}
          siteManager={this.props.siteManager}
  			/>
  		</div>);
    }
    //console.log('Table.createForm not showing');
  }

  checkInputsForNewKey = (oldInputs, newInputs, formType) =>
  {
    var newInputFound = false;
    var keys = Object.keys(newInputs);
    for(var i = 0; i < keys.length; i++)
    {
      try
      {
        if(!oldInputs[i] || oldInputs[i].id !== newInputs[i].id)
        {
          newInputFound = true;
          break;
        }
      }
      catch(err)
      {
        return false;
      }
    }
    // Check old inputs to make sure all exist in new inputs now
    keys = Object.keys(oldInputs);
    for(var i = 0; i < keys.length; i++)
    {
      try
      {
        if((!newInputs[i] || newInputs[i].id !== oldInputs[i].id) && oldInputs[i].type !== 'submit')
        {
          newInputFound = true;
          break;
        }
      }
      catch(err)
      {
        return false;
      }
    }
    return newInputFound;
  }

  // MARK: - Filter related
  filterOnChange = (id, evt) =>
  {
    var filters = this.state.filters;
    if(evt.target.value.length > 0)
    {
      filters.set(id, evt.target.value.toLowerCase());
    }
    else
    {
      filters.delete(id);
    }
    this.setState({ filters: filters });
  }

  /**
    Allow parent to pass us new search params after table instantiated so we can ovverride the search params
  */
  overrideSearchParams = (searchParams) =>
  {
    // Clone current map of filters
    const filters = new Map();
    const keys = Object.keys(this.state.filters);
    for(let i = 0; i < keys.length; i++)
    {
      filters.set(this.state.filters.get(keys[i]));
    }

    // Apply overrides
    for(let i = 0; i < searchParams.length; i++)
    {
      filters.set(searchParams[i].id, searchParams[i].value.toLowerCase());
    }
    this.setState({ filters: filters });
  }

  /**
    Check if filters have changed
    @param  {Map} oldFilters Filters pre update
    @param  {Map} newFilters  Filters post update
  */
  didFiltersChange = (oldFilters, newFilters) =>
  {
    return (JSON.stringify(oldFilters) === JSON.stringify(newFilters))
  }



  // MARK: - Render
  componentDidUpdate()
  {

    // Form inputs can be manually changed by parent after we are instantiated
    // handle checking for change here so we avoid an infinite update loop by doing it in shouldComponentUpdate
    if(this._updateFormInputsChanged && this._createFormInputsChanged)
    {
      this._updateFormInputsChanged = false;
      var updateFormInputs = [...this.props.updateFormInputs];
      if(updateFormInputs)
      {
        updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      this._createFormInputsChanged = false;
      var createFormInputs = [...this.props.createFormInputs];
      if(createFormInputs)
      {
        createFormInputs.push({
          label: '',
          id: 'submit',
          element: 'input',
          type: 'submit',
          value: this.props.createForm.submitBtnText ? this.props.createForm.submitBtnText : 'Create Record',
          onClick: this.createFormOnSubmit,
          class: 'btn-block  .border .border--white',
          disabled: true,
          requireValidForm: true,
        });
      }
      this.setState({ updateFormInputs: updateFormInputs, createFormInputs: createFormInputs });
    }
    else if(this._updateFormInputsChanged)
    {
      this._updateFormInputsChanged = false;
      var updateFormInputs = [...this.props.updateFormInputs];
      if(updateFormInputs)
      {
        updateFormInputs.push(  {label: '', id: 'submit', element: 'input', type: 'submit', value: 'Update Record', onClick: this.updateFormOnSubmit, class: 'btn-block  .border .border--white', disabled: true});
      }
      this.setState({ updateFormInputs: updateFormInputs });
    }
    else if(this._createFormInputsChanged)
    {
      this._createFormInputsChanged = false;
      var createFormInputs = [...this.props.createFormInputs];
      if(createFormInputs)
      {
        createFormInputs.push({
          label: '',
          id: 'submit',
          element: 'input',
          type: 'submit',
          value: this.props.createForm.submitBtnText ? this.props.createForm.submitBtnText : 'Create Record',
          onClick: this.createFormOnSubmit,
          class: 'btn-block  .border .border--white',
          disabled: true,
          requireValidForm: true,
        });
      }
      this.setState({ createFormInputs: createFormInputs });
    }
  }

  shouldComponentUpdate(nextProps, nextState)
  {
    // If style manager provided now, update css
    if(nextProps.siteManager !== null && this.props.siteManager === null)
    {
      this._css = this.styleComponent(nextProps.siteManager);
    }

    if(!this._updateFormInputsChanged)
    {
      this._updateFormInputsChanged = this.checkInputsForNewKey(this.state.updateFormInputs, nextProps.updateFormInputs, 'update');
    }

    if(!this._createFormInputsChanged)
    {
      this._createFormInputsChanged = this.checkInputsForNewKey(this.state.createFormInputs, nextProps.createFormInputs, 'create');
    }

    var shouldUpdate = (this.props.data !== nextProps.data ||
            this.props.headers !== nextProps.headers ||
            this.props.model !== nextProps.model ||
            this.props.selectAllEnabled !== nextProps.selectAllEnabled ||
            this.props.multiSelectEnabled !== nextProps.multiSelectEnabled ||
            this.props.defaultSort !== nextProps.defaultSort ||
            this.props.sortEnabled !== nextProps.sortEnabled ||
            this.props.title !== nextProps.title ||
            this.props.isDeleteAvailable !== nextProps.isDeleteAvailable ||
            this.props.updateForm !== nextProps.updateForm ||
            this.props.updateFormInputs !== nextProps.updateFormInputs ||
            this.props.createFormInputs !== nextProps.createFormInputs ||
            this.props.createForm !== nextProps.createForm ||
            this.props.siteManager !== nextProps.siteManager ||
            this.state.order !== nextState.order ||
            this.state.orderBy !== nextState.orderBy ||
            this.state.selected !== nextState.selected ||
            this.state.page !== nextState.page ||
            this.state.dense !== nextState.dense ||
            this.state.rowsPerPage !== nextState.rowsPerPage ||
            this.state.updateModalIsVisible !== nextState.updateModalIsVisible ||
            this.state.updateFormInputs !== nextState.updateFormInputs ||
            this.state.createModalIsVisible !== nextState.createModalIsVisible ||
            this.state.createFormInputs !== nextState.createFormInputs ||
            this.state.viewModalIsVisible !== nextState.viewModalIsVisible ||
            this.state.viewFormInputs !== nextState.viewFormInputs ||
            this.didFiltersChange(this.state.filters, nextState.filters)
        );
      return shouldUpdate;
  }

  render()
  {
    var emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, (this.props.data ? this.props.data.length : 0) - this.state.page * this.state.rowsPerPage);

    // If no heads are filters we will hide filter row
    let hasAnyFilters = this.props.headers.filter( (filter) => filter.filter);
    hasAnyFilters = (hasAnyFilters.length > 0);

    return (
      <this._css>
        <Paper className='table-inner'>

          {/* Filters displayed on top for layout 1 */}
          {this.props.layout === 1 &&
          <Filter
            layout={this.props.layout}
            filters={this.props.headers ? this.props.headers.filter(header => header.filter) : []}
            filterValues={this.state.filters}
            selectEnabled={this.props.selectEnabled}
            onChange={this.filterOnChange}
            disabled={this.state.selected.length !== 0}
          />}

          {/* Tool bar is our action list */}
          <Toolbar
            layout={this.props.layout}

            isPrintAvailable={this.props.isPrintAvailable}
            isCsvAvailable={this.props.isCsvAvailable}
            isDeleteAvailable={this.props.isDeleteAvailable}

            isUpdateAvailable={this.props.updateForm.isEnabled}
            updateButtonOnClick={this.updateButtonOnClick}

            isCreateAvailable={this.props.createForm.isEnabled}
            createButtonOnClick={this.createButtonOnClick}

            customButton1={this.props.customButton1}

            isViewAvailable={this.props.viewForm.isEnabled}
            viewButtonOnClick={this.viewButtonOnClick}

            cookies={this.props.cookies}
            tableDidStartLoading={this.props.tableDidStartLoading}
            tableDidFinishLoading={(action, message, error) => this.tableDidFinishLoading(action, message, error)}
            selected={this.state.selected}

            title={this.props.title}
            titleIcon={this.props.titleIcon}

            model={this.props.model}
            getTableDataForSelectedRows={this.getTableDataForSelectedRows}

            siteManager={this.props.siteManager}
          />
          <div className='table-wrapper'>
            <Table
              className='table'
              style={this.props.paginationEnabled === false ? {marginBottom: '0px'} : {}}
              aria-labelledby="tableTitle"
              size={this.state.dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              {/* Column headers, select all, and sorting */}
              <Header
                numSelected={this.state.selected.length}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.data ? this.props.data.length : 0}
                headers={this.props.headers}
                selectEnabled={this.props.selectEnabled}
                selectAllEnabled={this.props.selectAllEnabled}
                sortEnabled={(this.props.sortEnabled !== undefined) ? this.props.sortEnabled : true}
                layout={this.props.layout}
              />

              {/* For layout2 display filters below columns */}
              {this.props.layout === 2 &&
              hasAnyFilters &&
              <Filter
                layout={this.props.layout}
                filters={this.props.headers ? this.props.headers : []}
                filterValues={this.state.filters}
                selectEnabled={this.props.selectEnabled}
                onChange={this.filterOnChange}
                disabled={this.state.selected.length !== 0}
              />}

              {/* Data */}
              <TableBody>
                {this.props.data && this.applyFiltersAndSort()
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row, rowIndex) =>
                  {
                    const isItemSelected = this.isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${rowIndex}`
                    var tableCells = null;
                    if(row)
                    {
                      // Iterate header columns and output rows of data
                      tableCells = this.props.headers.map((header, headerIndex) =>
                      {
                        //console.log(row);
                        var visibleTextFieldName = header.id; // Header ID is the field in our data we want to display
                        var visibleText = "";

                        // Photo
                        if(header.id.indexOf('photo') !== -1 || header.id.indexOf('member.imageURL') !== -1 ||
                            header.id.indexOf('idImage') !== -1)
                        {
                          let src = this.extractValueFromPointer(visibleTextFieldName, row);
                          visibleText = (src ? <div className="image-cropper"><img src={src} alt='Profile image' className='profile-pic' /></div> : '')
                        }
                        else
                        {
                          visibleText = this.extractValueFromPointer(visibleTextFieldName, row);
                        }
                        // First column
                        if(headerIndex === 0)
                        {
                          let temp = '';
                          if(visibleTextFieldName === 'time' || visibleTextFieldName === 'createdOn' || visibleTextFieldName === 'pClass.time')
                          {
                            temp = this.formatDate(visibleText);
                          }
                          else
                          {
                            if(typeof visibleText === 'object')
                            {
                              temp = visibleText;
                            }
                            else
                            {
                              temp = visibleText.toString();
                            }
                          }
                          return <TableCell
                                    component="th"
                                    key={rowIndex + '-' + headerIndex}
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    size="small"
                                  >{temp}</TableCell>;
                        }
                        else // Additional columns
                        {
                          var temp = visibleText;
                          try
                          {
                            temp = visibleText.split(/<br\/>/).join("");
                          }
                          catch(err){}

                          // Tinme field
                          if(visibleTextFieldName === 'time' || visibleTextFieldName === 'createdOn' || visibleTextFieldName === 'pClass.time')
                          {
                            return <TableCell
                                      key={rowIndex + '-' + headerIndex}
                                      align="left"
                                      padding="none"
                                      size="small"
                                    >{this.formatDate(temp)}</TableCell>;
                          }
                          else if(header.isObject)
                          {
                            return <TableCell
                                      key={rowIndex + '-' + headerIndex}
                                      align="left"
                                      padding="none"
                                      size="small"
                                    ><pre>{temp !== undefined ? JSON.stringify(temp, null, 4) : ''}</pre></TableCell>;
                          }

                          if(temp !== undefined)
                          {
                            temp = header.id.indexOf('photo') === -1 && header.id.indexOf('image') === -1 && header.id.indexOf('idImage') === -1 ? temp.toString() : temp;
                          }
                          else
                          {
                            temp = '';
                          }

                          // See if we are meant to use a badge
                          if(header.badgeMap)
                          {
                            temp = <CBadge color={header.badgeMap[temp]}>{temp}</CBadge>;
                          }
                          return <TableCell
                                    key={rowIndex + '-' + headerIndex}
                                    align="left"
                                    padding="none"
                                    size="small"
                                  >{temp}</TableCell>;
                        }
                      });
                    }


                    return (
                      <TableRow
                        hover
                        onClick={event => this.tableRowOnClick(event, row._id)}
                        role="checkbox"
                        aria-checked={this.isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={this.isItemSelected}
                      >
                        {this.props.selectEnabled !== false &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            className="table-checkbox"
                          />
                        </TableCell>}
                        {tableCells}
                      </TableRow>
                    );
                  })}
                {this.emptyRows > 0 && (
                  <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {this.props.paginationEnabled !== false &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={this.props.data ? this.applyFiltersAndSort().length : 0}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />}
        </Paper>
        <TransitionModal
  				isOpen={this.state.updateModalIsVisible}
  				onClose={this.updateModalOnClose}
  				modalContent={this.updateForm()}
  			/>
        <TransitionModal
  				isOpen={this.state.createModalIsVisible}
  				onClose={this.createModalOnClose}
  				modalContent={this.createForm()}
  			/>
        <TransitionModal
  				isOpen={this.state.viewModalIsVisible}
  				onClose={this.viewModalOnClose}
  				modalContent={this.viewForm()}
  			/>
      </this._css>
    );
  }

  // Style component
  styleComponent = (siteManager) =>
  {

    var checkboxSelectedBg = '#F50057';
    if(siteManager !== null)
    {
      checkboxSelectedBg = siteManager.getColorFor('Table', 'Checkbox (Row Selected)');
    }

    return StyledComponent.div`

      .table-inner
      {
        width: 100%;
        margin-bottom: 0px;
      }
      .table-wrapper
      {
        overflow-x: auto;
        width: 100%;
        display: flex;
      }
      .table
      {
        min-width: ${this.props.isCondensed ? '400px' : '750px'};
      }
      .MuiCheckbox-colorSecondary.Mui-checked
      {
        color: ${checkboxSelectedBg} !important;
      }

      width: 100%;
      margin-top: 0px;
    `;
  }
}/*
<TransitionModal
  isOpen={this.state.updateModalIsVisible}
  onClose={this.updateModalOnClose}
  title={`Modifying record: ${(this.state.selected ? this.state.selected[0] : '')}`}
  description='Fill out the form below to update this record'
  modalContent={this.updateForm()}
/>
*/


export default EnhancedTable;
