import React from 'react';
import TextField from '@material-ui/core/TextField';

const Layout1 = ({  filters,
                    onChange,
                    disabled  }) =>
{
  /**
    https://material-ui.com/components/text-fields/#textfield
      standard, filled, outlined
  */
  const variant = "outlined";
  const filterStyle =
  {
    paddingLeft: '25px',
    padding: '12px 25px',
    width: `${100 / filters.length}%`
  };

  return (
    <div className="table-filter">
    {filters.length > 0 &&
      filters.map((filter, index) => (
        <div style={filterStyle} key={`filter-column-${index}`}>
          <TextField disabled={disabled} variant={variant} label={filter.label} onChange={(evt) => onChange(filter.id, evt)} />
        </div>
      ))}
    </div>
  );
}

export default Layout1;
