import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Form } from 'react-bootstrap';
import { Datetime } from '../../utils';
import { ContextMenu } from '.';

import AppText from '../../constant/text';

export const Comment = ({ comment,
                          updateComment,
                          deleteComment,
                          canEdit,
                          canReport }) =>
{
  const [isHovered, setIsHovered] = useState(false);
  const [contextMenuIsShowing, setContextMenuIsShowing] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [isEditMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState(comment.message);

  return (
  <Grid container direction='row' className='comment-user-container'>
    <div
      className='col-xl-2 col-lg-2 col-md-1 col-sm-2 col-12'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Avatar
        alt="Profile image"
        src={comment.createdBy.photo}
        className={'comment-avatar'}
      />
    </div>
    <div
      className='col-xl-10 col-lg-10 col-md-11 col-sm-10 col-12'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Grid className='comment-name-date'>
        <div className='comment-name-row'>
          <Typography
            variant="h6"
            classes={{h6: 'post-name'}}
            display='block'
            align={'left'}
          >
            {comment.createdBy.firstName + ' ' + comment.createdBy.lastName}
          </Typography>
          {isHovered &&
          <span
            className='ti-more comment-actions'
            onClick={(e) =>
            {
              setContextMenuIsShowing(true);
              setAnchorElement(e.target);
            }}
          />}
          <ContextMenu
            closeMenu={() =>
            {
              setContextMenuIsShowing(false);
              setAnchorElement(null);
            }}
            isEditMode={isEditMode}
            editOnClick={() =>
            {
              setContextMenuIsShowing(false);
              setAnchorElement(null);
              setEditMode(true);
              setEditText(comment.message);
            }}
            cancelEditOnClick={() =>
            {
              setContextMenuIsShowing(false);
              setAnchorElement(null);
              setEditMode(false);
            }}
            deleteOnClick={() =>
            {
              setContextMenuIsShowing(false);
              setAnchorElement(null);
              deleteComment(comment._id);
            }}
            anchorElement={anchorElement}
            canEdit={canEdit}
            canReport={canReport}
            isOpen={contextMenuIsShowing}
          />
        </div>
        {!isEditMode &&
        <Typography
          variant="h6"
          classes={{h6: 'comment-text'}}
          display='block'
          align={'left'}
        >
          {comment.message}
        </Typography>}
        {isEditMode &&
        <Form.Control
          type="text"
          onChange={(e) => setEditText(e.target.value)}
          value={editText}
        />
        }
        <div className='comment-edit-row'>
          <Typography
            variant="subtitle2"
            classes={{subtitle2: 'post-date'}}
            align={'left'}
            display='block'
          >
            {Datetime.formatFullDate(comment.createdOn)}
          </Typography>
          {isEditMode &&
          <Button
            onClick={() =>
            {
              updateComment(comment._id, editText);
              setEditMode(false);
            }}
            className='comment-save-btn'
          >{AppText.posts.post.comment.save}</Button>}
        </div>
      </Grid>
    </div>
  </Grid>);
}
//{'May 22nd, 2014 6:00 PM'}

export default Comment;
