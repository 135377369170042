import { ApiManager } from '../../managers';
import { Command } from '..';

export async function UpdateDataCommand({ setIsLoading, model, id, params, dataSetId, dataSetField, modifier })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tUpdateDataCommand.execute()');
    setIsLoading(true);
    try
    {
      const response = await ApiManager.SendRequest(
			{
        route: 'data',
        action: 'update',
        model: model,
        id: id,
        params: params
			});

      console.log(response);

      if(response.error !== null)
      {
        setIsLoading(false);
        showAlert(true, 'Uh-oh', response.error, 'danger');
        return;
      }

      // Save updated record in data set
      if(!modifier)
      {
        const dataSet = dataMgr.getData(dataSetId);
        for(let i = 0; i < dataSet[dataSetField].length; i++)
        {
          if(dataSet[dataSetField][i]._id.toString() === response.results._id.toString())
          {
            dataSet[dataSetField][i] = response.results;
            break;
          }
        }
        dataMgr.setData(dataSetId, dataSet);
      }
      else
      {
        modifier(response.results);
      }

      setIsLoading(false);

      // Tell observers data set has updated
      dataMgr.notifyObservers(dataSetId);

      // Send back result
      return response.results;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
