import React from 'react';
import StyledComponent from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CsvButton from '../CsvButton';
import DeleteButton from '../DeleteButton';

const Layout1 = ({  selected,
                    title,
                    getTableDataForSelectedRows,
                    tableDidFinishLoading,
                    tableDidStartLoading,
                    isCsvAvailable,
                    cookies,
                    model,
                    isDeleteAvailable,
                    isUpdateAvailable,
                    updateButtonOnClick,
                    isCreateAvailable,
                    createButtonOnClick,
                    viewButtonOnClick,
                    customButton1 }) =>
{
  return (
    <_css>
      <Toolbar className={`table-header ${selected.length > 0 ? 'table-header-selected' : ''}`}>

        {selected.length > 0 ? (
          <Typography className='table-header-title' color="inherit" variant="subtitle1">
            {selected.length} selected
          </Typography>
        ) : (
          <Typography className='table-header-title' variant="h6" id="tableTitle">
            {/*title*/}
          </Typography>
        )}

         {selected.length > 0 && isCsvAvailable &&
         <CsvButton csvData={getTableDataForSelectedRows({isForCsv: true})} fileName='csvexport' />}

         {selected.length > 0 && isDeleteAvailable &&
         <DeleteButton data={selected} cookies={cookies}
            tableDidStartLoading={tableDidStartLoading}
            tableDidFinishLoading={(action, message, error) => tableDidFinishLoading(action, message, error)}
            model={model}
         />}

         {/* View button */}
         {selected.length === 1 &&
         <Tooltip title="View selected row">
           <IconButton onClick={viewButtonOnClick} aria-label="row">
             <VisibilityIcon />
           </IconButton>
         </Tooltip>}

         {selected.length === 1 && isUpdateAvailable &&
           <Tooltip title="Update selected rows">
             <IconButton onClick={updateButtonOnClick} aria-label="row">
               <EditIcon />
             </IconButton>
           </Tooltip>}

         {selected.length === 0 && isCreateAvailable &&
           <Tooltip title="Create new record">
             <IconButton onClick={createButtonOnClick} aria-label="row">
               <AddIcon />
             </IconButton>
           </Tooltip>}

          {selected.length === 1 && customButton1 &&
            customButton1({ selected: selected })
          }
      </Toolbar>
    </_css>
  );
}

const _css = StyledComponent.div`
  .table-header-selected
  {
    color: #F50057;
    background-color: #FFE2EC;
  }
  .table-header
  {
    padding-left: 16px;
    padding-right: 8px;
  }
  .table-header-title
  {
    flex: 1;
  }
`;

export default Layout1;
