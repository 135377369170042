import React from 'react';

const EditTos = React.lazy(() => import('./pages/tos/editTos'));
const Establishments = React.lazy(() => import('./pages/establishments/establishments'));
const Insert = React.lazy(() => import('./pages/insert/insert'));
const JobManager = React.lazy(() => import('./pages/jobManager/jobManager'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const ModelManager = React.lazy(() => import('./pages/modelManager/modelManager'));
const NoMatch = React.lazy(() => import('./pages/noMatch/noMatch'));
const PageController = React.lazy(() => import('./components/page/pageController'));
const Scans = React.lazy(() => import('./pages/scans/scans'));
const Sharing = React.lazy(() => import('./pages/sharing/sharing'));
const Users = React.lazy(() => import('./pages/users/users'));
const View = React.lazy(() => import('./pages/view/view'));
const ViewTos = React.lazy(() => import('./pages/tos/viewTos'));

const Routes = [
  /* PageController managed routes */
  {
    path: '/',
    name: '',
    component: PageController,
  },
  {
    path: '/accept-invite',
    name: 'Accept Invite',
    component: PageController,
  },
  {
    path: '/confirm-reset',
    name: 'Confirm Reset',
    component: PageController,
  },
  {
    path: '/login',
    name: 'Login',
    component: PageController,
  },
  {
    path: '/register',
    name: 'Register',
    component: PageController,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: PageController,
  },

  // Not page controller routes
  {
    path: '/edit-tos',
    name: 'EditTos',
    component: EditTos,
  },
  {
    path: '/establishments',
    name: 'Establishments',
    component: Establishments,
  },
  {
    path: '/home',
    name: 'Home',
    component: Users,
  },
  {
    path: '/insert',
    name: 'Insert',
    component: Insert,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobManager,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: ({ cookies, updateMasterState, siteManager, history }) =>
    {
      return (
        <Logout
          cookies={cookies}
          updateMasterState={updateMasterState}
          siteManager={siteManager}
          history={history}
        />
      )
    },
  },
  {
    path: '/model-manager',
    name: 'Model Manager',
    component: ModelManager,
  },
  {
    path: '/scans',
    name: 'Scans',
    component: Scans,
  },
  {
    path: '/sharing',
    name: 'Sharing',
    component: Sharing,
  },
  {
    path: '/tos',
    name: 'Tos',
    component: ViewTos,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/view',
    name: 'View',
    component: View,
  },

  // This must be last
  {
    component: NoMatch,
  },
];

export default Routes;
