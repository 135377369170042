import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Form } from 'react-bootstrap';
import { Datetime } from '../../utils';

import AppText from '../../constant/text';

export const ContextMenu = ({ closeMenu,
                              acceptOnClick,
                              declineOnClick,
                              anchorElement,
                              isOpen }) =>
{
  return (
  <div>
    <Menu
      id="simple-menu"
      anchorEl={anchorElement}
      keepMounted
      open={isOpen}
      onClose={() =>
      {
        closeMenu();
      }}
      >
        <MenuItem onClick={closeMenu}>Cancel</MenuItem>
        <MenuItem onClick={acceptOnClick}>Accept</MenuItem>
        <MenuItem onClick={declineOnClick}>Decline</MenuItem>
    </Menu>
  </div>);
}

export default ContextMenu;
