import { ApiManager } from '../../managers';
import { Command } from '..';

export async function SetActiveAppCommand({ setIsLoading, activeApp })
{
  return new Command(async(dataMgr, showAlert) =>
  {
    console.log('\t\tSetActiveAppCommand.execute()');
    setIsLoading(true);
    try
    {
      // Save updated record in data set
      const dataSet = dataMgr.getData('app');
      dataSet['active'] = activeApp;
      dataMgr.setData('app', dataSet);

      // Update cookie
      dataMgr.setCookie('app', activeApp);

      setIsLoading(false);

      // Tell observers data set has updated
      dataMgr.notifyObservers('app');

      // TODO: Make items in CSideBar dynamic and avoid page refresh
      // Reload page
      window.location.reload();

      // Send back result
      return null;
    }
    catch(err)
    {
      console.log(err);
      setIsLoading(false);
      showAlert(true, 'Uh-oh', 'An error has occurred, please try again or contact support.\nError: 10 ' + err, 'danger');
    }
  });
}
