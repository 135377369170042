import React, { Suspense } from 'react';
import StyledComponent from 'styled-components';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CContainer, CFade } from '@coreui/react';
// Helmet: Dynamic page title
import { Helmet } from 'react-helmet';

import { NavList, NavigationBar } from '../components/nav';
import NotificationAudio from '../asset/notification.mp3';
import { Footer } from '../components/footer';
import Routes from '../routes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Layout2 = ({  appLayout,
                    title,
                    siteManager,
                    selectedNavItem,
                    showTitleInNav,
                    showAlert,
                    token,
                    notificationManager,
                    path,
                    updateMasterState,
                    forward,
                    cookies,
                    isMobile,
                    pushManager,
                    reloadPageData,
                    redirect,
                    pageComponents,
                    alertDismissible,
                    components,
                    notificationAudioRef,
                    navListIsShowing
                }) =>
{
  return (
    <div className="c-app c-default-layout">

      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Router>

        {siteManager &&
        token &&
        <NavList
          layout={appLayout}
          active={selectedNavItem}
          siteManager={siteManager}
          user={cookies.get('user')}
          showAlert={showAlert}
          setIsLoading={(isLoading) => updateMasterState({ isLoading: isLoading})}
          setSelectedNavItem={(selected, cb) =>
          {
            updateMasterState({ selectedNavItem: selected }, cb);
          }}
          isShowing={navListIsShowing}
          setIsShowing={(isShowing) =>
          {
            updateMasterState({ navListIsShowing: isShowing });
          }}
        />}

        <div className="c-wrapper">
          {siteManager &&
          <NavigationBar
            title={title}
            selectedNavItem={selectedNavItem}
            showTitle={showTitleInNav}
            showAlert={showAlert}
            loggedIn={token !== undefined}
            notificationManager={notificationManager}
            siteManager={siteManager}
            path={path.replace('/', '')}
            updateMasterState={updateMasterState}
            forward={forward}
            cookies={cookies}
            isMobile={isMobile}
            navListIsShowing={navListIsShowing}
          />}

          {alertDismissible}

          <div className="c-body">
            <main className="c-main" style={{backgroundColor: '#ebedef'}}>
              <CContainer fluid>
                <Suspense fallback={loading}>
                  <Switch>
                    {Routes.map((route, idx) =>
                    {
                      let thePageComponents = [];
                      if(route.name === 'home')
                      {
                        thePageComponents = components;
                      }
                      else if(pageComponents)
                      {
                        let tempComponents = [];
                        if(route.path && route.path !== '/')
                        {
                          tempComponents = pageComponents.get(route.path.replace('/', ''));
                        }
                        else if(route.path === '/')
                        {
                          tempComponents = pageComponents.get('login');
                        }
                        if(tempComponents)
                        {
                          thePageComponents = [...tempComponents];
                        }
                      }

                      return (
                        <Route
                          key={`route-${idx}`}
                          path={route.path}
                          exact={true}
                          name={route.name}
                          render={(props) =>
                          (
                            <CFade>
                              <route.component {...props}
                                appLayout={appLayout}
                                showAlert={showAlert}
                                path={path.replace('/', '')}
                                updateMasterState={updateMasterState}
                                cookies={cookies}
                                reloadPageData={reloadPageData}
                                siteManager={siteManager}
                                pushManager={pushManager}
                                notificationManager={notificationManager}
                                isMobile={isMobile}
                                selectedNavItem={selectedNavItem}
                                pageComponents={thePageComponents}
                                forward={forward}
                              />
                            </CFade>
                          )}
                        />
                      );
                    })}

                  </Switch>
                </Suspense>
                {redirect &&
                <Redirect to={{ pathname: redirect }} />}
              </CContainer>
            </main>
          </div> {/* End c-body */}

          <Footer
            copyrightText={'Copyright 2021 | Hathor BISU'}
            siteManager={siteManager}
          />
        </div> {/* End c-wrapper */}
      </Router>

      <audio
        ref={notificationAudioRef}
        autoPlay={true}
        muted={"muted"}
        src={NotificationAudio}
      />
    </div>
  );
}

export default Layout2;
